import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

const getBackgroundColor = (inputStyle: InputStyle) => {
  switch (inputStyle) {
    case InputStyle.RED:
      return 'linear-gradient(229deg, #FF0000 0%, #FF7F7F 100%)';
    case InputStyle.ORANGE:
      return 'linear-gradient(229deg, #FFA500 0%, #FFD280 100%)';
    case InputStyle.GREEN:
      return 'linear-gradient(229deg, #65D83C 0%, #AFD83C 100%)';
    default:
      return 'transparent';
  }
};

const Input = styled.input<{
  inputStyle: InputStyle;
  width?: string;
  height?: string;
}>`
  height: ${(props) => props.height};
  max-height: 50px;
  width: ${(props) => props.width};
  overflow: hidden;

  border: none;
  outline: none;
  box-shadow: none;
  background: ${(props) => getBackgroundColor(props.inputStyle)};

  padding: 12px;
  border: ${(props) =>
    props.inputStyle === InputStyle.ERROR ? '1px solid red' : '1px solid #d9dee2'};
  border-radius: 10px;

  &:disabled {
    cursor: no-drop;
    background: #f2f2f2;
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ${(props) =>
    props.inputStyle === InputStyle.WHITE &&
    `
    color: #FFFFFF;

    ::placeholder { 
      color: #FFFFFF;
    }
  `}

  ${(props) =>
    props.inputStyle === InputStyle.ERROR &&
    `
    color: red;

    ::placeholder { 
      color: red;
    }
  `}
`;

const Title = styled.div<{
  inputStyle: InputStyle;
}>`
  font-size: 14px;
  line-height: 1.5;
  color: #434d56;

  ${(props) =>
    props.inputStyle === InputStyle.ERROR &&
    `
    color: red;
  `}
`;

const Error = styled.div`
  color: red;
`;

export enum InputStyle {
  WHITE,
  NORMAL,
  GREEN,
  ORANGE,
  RED,
  ERROR
}

type CustomInputProps = {
  defaultValue: string | number;
  placeholder?: string;
  errorMessage?: string;
  type?: string;
  title?: string;
  isDisabled?: boolean;
  inputStyle?: InputStyle;
  width?: string;
  height?: string;
  autoComplete?: string;
  debounceTime?: number;
  onClick?: () => void;
  callBack?: (property?: string) => (value: string | number) => void;
};

const CustomInput = ({
  type = 'text',
  defaultValue,
  placeholder,
  title,
  inputStyle = InputStyle.NORMAL,
  errorMessage,
  width = '200px',
  height = '50px',
  isDisabled = false,
  autoComplete = 'off',
  debounceTime = 0,
  onClick,
  callBack
}: CustomInputProps) => {
  const [value, setValue] = useState<number | string>(type === 'text' ? '' : 0);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const debouncedExec = useCallback(
    debounce((input: string | number) => {
      if (callBack) {
        const exec = callBack();
        exec(input);
      }
    }, debounceTime),
    [callBack]
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue: string | number = e.target.value;
    if (type === 'number') {
      newValue = newValue.replace(/[.,]/g, '.');
      setValue(newValue);
      debouncedExec(Number(newValue));
    } else {
      setValue(newValue);
      debouncedExec(newValue);
    }
  };

  return (
    <div>
      {title && <Title inputStyle={inputStyle}>{title}</Title>}
      <Input
        type={type}
        placeholder={placeholder}
        value={value}
        disabled={isDisabled}
        onInput={handleChange}
        onClick={onClick}
        inputStyle={inputStyle}
        width={width}
        height={height}
        autoComplete={autoComplete}
      />
      {inputStyle === InputStyle.ERROR && errorMessage && <Error>{errorMessage}</Error>}
    </div>
  );
};

export default CustomInput;

const STAGING_ENV = ''; // never push this one
const DEBUG_ENV = ''; // never push this one
const PROD_ENV = 'https://internal-staging.pcbelectronics.fr';

// Get
export const getAdmin = `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/admins`;
export const getCompanies = `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/company/`;
export const getCompanyContact = (companyId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/company/${companyId}/contact/`;
export const getContacts = `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/contact/`;
export const getSpecificContacts = (companyId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/company/${companyId}/contact/`;
export const getProviders = `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/provider/`;
export const getSpecificProviderContacts = (providerId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/provider/${providerId}/provider_contact/`;
export const getSpecificProvider = (providerId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/provider/${providerId}/`;
export const getSpecificCompany = (companyId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/company/${companyId}/`;
export const getProviderCurrency = `${
  DEBUG_ENV || STAGING_ENV || PROD_ENV
}/backend/provider_currency/`;
export const getProvidersContact = `${
  DEBUG_ENV || STAGING_ENV || PROD_ENV
}/backend/provider_contact/`;
export const getQuotations = `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/quotation/`;
export const getClause = `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/clause/`;
export const getSpecificQuotations = (companyId: number, contactId: number, quotationId: number) =>
  `${
    DEBUG_ENV || STAGING_ENV || PROD_ENV
  }/backend/company/${companyId}/contact/${contactId}/quotation/${quotationId}/`;
export const getSpecificQuotation = (quotationId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/quotation/${quotationId}/`;

export const getQuotationItems = (
  companyId: number,
  contactId: number,
  quotationId: number,
  quotationVersion: number
) =>
  `${
    DEBUG_ENV || STAGING_ENV || PROD_ENV
  }/backend/company/${companyId}/contact/${contactId}/quotation/${quotationId}/quotation_version/${quotationVersion}/quotation_item/`;
export const getQuotationVersion = (
  companyId: number,
  contactId: number,
  quotationId: number,
  quotationVersion: number
) =>
  `${
    DEBUG_ENV || STAGING_ENV || PROD_ENV
  }/backend/company/${companyId}/contact/${contactId}/quotation/${quotationId}/quotation_version/${quotationVersion}/`;
export const getItem = (itemId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/item/${itemId}/`;
export const getCustomerOrders = `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/customer_order/`;
export const getSpecificOrder = (orderId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/customer_order/${orderId}/`;
export const getCustomerDeliveryNote = () =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/customer_delivery_note/`;
export const getCustomerInvoices = `${
  DEBUG_ENV || STAGING_ENV || PROD_ENV
}/backend/customer_invoice/`;
export const getSpecificCustomerInvoices = (invoiceId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/customer_invoice/${invoiceId}/`;
export const getSpecificProviderInvoices = (invoiceId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/provider_invoice/${invoiceId}/`;
export const getCustomerCreditNote = `${
  DEBUG_ENV || STAGING_ENV || PROD_ENV
}/backend/customer_credit_note/`;
export const getCustomerPayment = `${
  DEBUG_ENV || STAGING_ENV || PROD_ENV
}/backend/customer_payment/`;
export const getRequestForQuotation = `${
  DEBUG_ENV || STAGING_ENV || PROD_ENV
}/backend/request_for_quotation/`;
export const getPurchase = `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/provider_purchase/`;
export const getSpecificPurchase = (purchaseId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/provider_purchase/${purchaseId}/`;
export const getProviderDeliveryNote = () =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/provider_delivery_note/`;
export const getProviderCreditNote = `${
  DEBUG_ENV || STAGING_ENV || PROD_ENV
}/backend/provider_credit_note/`;
export const getProviderInvoices = `${
  DEBUG_ENV || STAGING_ENV || PROD_ENV
}/backend/provider_invoice/`;
export const getProviderPayment = `${
  DEBUG_ENV || STAGING_ENV || PROD_ENV
}/backend/provider_payment/`;
export const getCompanyActivities = (companyId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/activities?company_id=${companyId}`;
export const getProviderActivities = (providerId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/activities?provider_id=${providerId}`;
export const getRelatedDocuments = `${
  DEBUG_ENV || STAGING_ENV || PROD_ENV
}/backend/related_documents`;
export const getGerFile = (
  companyId: number,
  contactId: number,
  quotationId: number,
  quotationVersion: number,
  quotationItem: number,
  quotationFileId: number
) =>
  `${
    DEBUG_ENV || STAGING_ENV || PROD_ENV
  }/backend/company/${companyId}/contact/${contactId}/quotation/${quotationId}/quotation_version/${quotationVersion}/quotation_item/${quotationItem}/quotation_file/${quotationFileId}/download/`;
export const getItemSearch = () => `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/item/search`;
export const getItemValidRef = () =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/item/is_ref_ok`;
export const getDocument = (documentId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/document/${documentId}/`;

// Post
export const postUploadGerFile = (
  companyId: number,
  contactId: number,
  quotationId: number,
  quotationVersion: number,
  quotationItem: number
) =>
  `${
    DEBUG_ENV || STAGING_ENV || PROD_ENV
  }/backend/company/${companyId}/contact/${contactId}/quotation/${quotationId}/quotation_version/${quotationVersion}/quotation_item/${quotationItem}/upload/`;
export const postCreateContact = (companyId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/company/${companyId}/contact/`;
export const postCreateAddress = (companyId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/company/${companyId}/address/`;
export const postCreateCompany = `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/company/`;
export const postCreateProvider = `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/provider/`;
export const postCreateProviderAddress = (companyId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/provider/${companyId}/address/`;
export const postCreateProviderContact = (companyId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/provider/${companyId}/provider_contact/`;
export const postCreateQuotation = (companyId: number, contactId: number) =>
  `${
    DEBUG_ENV || STAGING_ENV || PROD_ENV
  }/backend/company/${companyId}/contact/${contactId}/quotation/`;
export const postDuplicateExistingItem = (itemId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/item/${itemId}/duplicate/`;
export const postDuplicateQuotation = (companyId: number, contactId: number, quotationId: number) =>
  `${
    DEBUG_ENV || STAGING_ENV || PROD_ENV
  }/backend/company/${companyId}/contact/${contactId}/quotation/${quotationId}/duplicate/`;

export const postCreateQuotationItem = (
  companyId: number,
  contactId: number,
  quotationId: number,
  quotationVersion: number
) =>
  `${
    DEBUG_ENV || STAGING_ENV || PROD_ENV
  }/backend/company/${companyId}/contact/${contactId}/quotation/${quotationId}/quotation_version/${quotationVersion}/quotation_item/`;
export const postItem = `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/item/`;
export const postDuplicateQuotationItem = (
  companyId: number,
  contactId: number,
  quotationId: number,
  quotationVersion: number,
  itemId: number,
  isNewReference: boolean
) =>
  `${
    DEBUG_ENV || STAGING_ENV || PROD_ENV
  }/backend/company/${companyId}/contact/${contactId}/quotation/${quotationId}/quotation_version/${quotationVersion}/quotation_item/${itemId}/duplicate/${
    isNewReference ? '?force_same_item=true' : ''
  }`;
export const postCreateCustomerOrder = `${
  DEBUG_ENV || STAGING_ENV || PROD_ENV
}/backend/customer_order/`;
export const postCustomerDeliveryNote = () =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/customer_delivery_note/`;
export const postCustomerCreditNote = () =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/customer_credit_note/`;
export const postCustomerInvoices = () =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/customer_invoice/`;
export const postCustomerPayment = `${
  DEBUG_ENV || STAGING_ENV || PROD_ENV
}/backend/customer_payment/`;
export const postRequestForQuotation = (companyId: number, contactId: number) =>
  `${
    DEBUG_ENV || STAGING_ENV || PROD_ENV
  }/backend/company/${companyId}/contact/${contactId}/request_for_quotation/`;
export const postPurchase = `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/provider_purchase/`;
export const postProviderInvoices = () =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/provider_invoice/`;
export const postProviderCreditNote = () =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/provider_credit_note/`;
export const postProviderPayment = `${
  DEBUG_ENV || STAGING_ENV || PROD_ENV
}/backend/provider_payment/`;
export const postQuotationItemPDF = (quotationVersion: number) =>
  `${
    DEBUG_ENV || STAGING_ENV || PROD_ENV
  }/backend/documents/quotation_version/${quotationVersion}/`;
export const postCustomerProformaInvoiceOrderPDF = (customerOrderId: number) =>
  `${
    DEBUG_ENV || STAGING_ENV || PROD_ENV
  }/backend/documents/customer_proforma_invoice/${customerOrderId}/`;
export const postCustomerOrderPDF = (customerOrderId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/documents/customer_order/${customerOrderId}/`;
export const postProviderPurchasePDF = (purchaseId: number) =>
  `${
    DEBUG_ENV || STAGING_ENV || PROD_ENV
  }/backend/documents/provider_purchase_order/${purchaseId}/`;
export const postCustomerDeliveryNotePDF = (deliveryNoteId: number) =>
  `${
    DEBUG_ENV || STAGING_ENV || PROD_ENV
  }/backend/documents/customer_delivery_note/${deliveryNoteId}/`;
export const postProviderDeliveryNotePDF = (deliveryNoteId: number) =>
  `${
    DEBUG_ENV || STAGING_ENV || PROD_ENV
  }/backend/documents/provider_delivery_note/${deliveryNoteId}/`;
export const postCustomerInvoicePDF = (invoiceId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/documents/customer_invoice/${invoiceId}/`;
export const postProviderInvoicePDF = (invoiceId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/documents/provider_invoice/${invoiceId}/`;
export const postCustomerCreditNotePDF = (creditNoteId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/documents/customer_credit_note/${creditNoteId}/`;
export const postProviderCreditNotePDF = (creditNoteId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/documents/provider_credit_note/${creditNoteId}/`;
export const postDocument = () => `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/document/`;
export const postLogIn = () => `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/token/`;

// Patch
export const patchCompany = (companyId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/company/${companyId}/`;
export const patchContact = (companyId: number, contactId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/company/${companyId}/contact/${contactId}/`;
export const patchAddress = (companyId: number, addressId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/company/${companyId}/address/${addressId}/`;
export const patchProvider = (companyId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/provider/${companyId}/`;
export const patchProviderAddress = (companyId: number, addressId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/provider/${companyId}/address/${addressId}/`;
export const patchProviderContact = (companyId: number, contactId: number) =>
  `${
    DEBUG_ENV || STAGING_ENV || PROD_ENV
  }/backend/provider/${companyId}/provider_contact/${contactId}/`;
export const patchQuotation = (companyId: number, contactId: number, quotationId: number) =>
  `${
    DEBUG_ENV || STAGING_ENV || PROD_ENV
  }/backend/company/${companyId}/contact/${contactId}/quotation/${quotationId}/`;
export const patchQuotationVersion = (
  companyId: number,
  contactId: number,
  quotationId: number,
  quotationVersion: number
) =>
  `${
    DEBUG_ENV || STAGING_ENV || PROD_ENV
  }/backend/company/${companyId}/contact/${contactId}/quotation/${quotationId}/quotation_version/${quotationVersion}/`;
export const patchQuotationItem = (
  companyId: number,
  contactId: number,
  quotationId: number,
  quotationVersion: number,
  itemId: number
) =>
  `${
    DEBUG_ENV || STAGING_ENV || PROD_ENV
  }/backend/company/${companyId}/contact/${contactId}/quotation/${quotationId}/quotation_version/${quotationVersion}/quotation_item/${itemId}/`;
export const patchItem = (itemId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/item/${itemId}/`;
export const patchNewQuotationVersion = (
  companyId: number,
  contactId: number,
  quotationId: number,
  quotationVersion: number
) =>
  `${
    DEBUG_ENV || STAGING_ENV || PROD_ENV
  }/backend/company/${companyId}/contact/${contactId}/quotation/${quotationId}/quotation_version/${quotationVersion}/duplicate/`;
export const patchOrder = (orderId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/customer_order/${orderId}/`;
export const patchCustomerDeliveryNote = (deliveryId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/customer_delivery_note/${deliveryId}/`;
export const patchCustomerCreditNote = (creditNoteId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/customer_credit_note/${creditNoteId}/`;
export const patchCustomerPayment = (paymentId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/customer_payment/${paymentId}/`;
export const patchRequestForQuotation = (companyId: number, contactId: number, rfqId: number) =>
  `${
    DEBUG_ENV || STAGING_ENV || PROD_ENV
  }/backend/company/${companyId}/contact/${contactId}/request_for_quotation/${rfqId}/`;
export const patchPurchase = (purchaseId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/provider_purchase/${purchaseId}/`;
export const patchProviderDeliveryNote = (deliveryId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/provider_delivery_note/${deliveryId}/`;
export const patchProviderCreditNote = (creditNoteId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/provider_credit_note/${creditNoteId}/`;
export const patchProviderPayment = (paymentId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/provider_payment/${paymentId}/`;
export const patchGeneralComment = () =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/edit_general_comment`;
export const patchItemRankOrder = (
  companyId: number,
  contactId: number,
  quotationId: number,
  quotationVersionId: number
) =>
  `${
    DEBUG_ENV || STAGING_ENV || PROD_ENV
  }/backend/company/${companyId}/contact/${contactId}/quotation/${quotationId}/quotation_version/${quotationVersionId}/update_rank/`;

// Del
export const delCompany = (companyId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/company/${companyId}/`;
export const delContact = (companyId: number, companyContactId: number) =>
  `${
    DEBUG_ENV || STAGING_ENV || PROD_ENV
  }/backend/company/${companyId}/contact/${companyContactId}/`;
export const delAddress = (companyId: number, companyAddressId: number) =>
  `${
    DEBUG_ENV || STAGING_ENV || PROD_ENV
  }/backend/company/${companyId}/address/${companyAddressId}/`;
export const delProvider = (companyId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/provider/${companyId}/`;
export const delProviderAddress = (companyId: number, companyAddressId: number) =>
  `${
    DEBUG_ENV || STAGING_ENV || PROD_ENV
  }/backend/provider/${companyId}/address/${companyAddressId}/`;
export const delProviderContact = (companyId: number, companyContactId: number) =>
  `${
    DEBUG_ENV || STAGING_ENV || PROD_ENV
  }/backend/provider/${companyId}/provider_contact/${companyContactId}/`;
export const delQuotation = (companyId: number, contactId: number, quotationId: number) =>
  `${
    DEBUG_ENV || STAGING_ENV || PROD_ENV
  }/backend/company/${companyId}/contact/${contactId}/quotation/${quotationId}/`;
export const delQuotationItem = (
  companyId: number,
  contactId: number,
  quotationId: number,
  quotationVersion: number,
  itemId: number
) =>
  `${
    DEBUG_ENV || STAGING_ENV || PROD_ENV
  }/backend/company/${companyId}/contact/${contactId}/quotation/${quotationId}/quotation_version/${quotationVersion}/quotation_item/${itemId}/`;
export const delOrder = (orderId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/customer_order/${orderId}/`;
export const delInvoice = (invoiceId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/customer_invoice/${invoiceId}/`;
export const delDeliveryNote = (deliveryNoteId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/customer_delivery_note/${deliveryNoteId}/`;
export const delCreditNote = (creditNoteId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/customer_credit_note/${creditNoteId}/`;
export const delRequestForQuotation = (companyId: number, contactId: number, rfqId: number) =>
  `${
    DEBUG_ENV || STAGING_ENV || PROD_ENV
  }/backend/company/${companyId}/contact/${contactId}/request_for_quotation/${rfqId}/`;
export const delPurchase = (purchaseId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/provider_purchase/${purchaseId}/`;
export const delCustomerPayment = (paymentId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/customer_payment/${paymentId}/`;
export const delProviderDeliveryNote = (deliveryNoteId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/provider_delivery_note/${deliveryNoteId}/`;
export const delProviderInvoice = (invoiceId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/provider_invoice/${invoiceId}/`;
export const delProviderPayment = (paymentId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/provider_payment/${paymentId}/`;

export const delDocument = (documentId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/document/${documentId}/`;

// put
export const putOrderItem = (orderId: number) =>
  `${DEBUG_ENV || STAGING_ENV || PROD_ENV}/backend/customer_order/${orderId}/edit/`;

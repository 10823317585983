import React, { useContext } from 'react';
import styled from 'styled-components';
import { AxiosResponse } from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';

import useApi from 'services/axios';
import {
  patchOrder,
  patchQuotation,
  patchQuotationVersion,
  postCreateQuotation
} from 'services/endpoints';
import { QuotationsContext } from 'contexts';

export const WiringSerializer = (reference: string) => {
  return {
    product_name: 'wiring',
    product_information: {
      reference: reference,
      provider_additional_comment: '',
      additional_comment: ''
    }
  };
};
export const MechanicSerializer = (reference: string) => {
  return {
    product_name: 'mechanic',
    product_information: {
      reference: reference,
      provider_additional_comment: '',
      additional_comment: ''
    }
  };
};
export const ComponentSerializer = (reference: string) => {
  return {
    product_name: 'component',
    product_information: {
      reference: reference,
      provider_additional_comment: '',
      additional_comment: ''
    }
  };
};

export const CAOSerializer = (reference: string) => {
  return {
    product_name: 'cao',
    product_information: {
      reference: reference,
      additional_comment: '',
      provider_additional_comment: '',
      source_files_provided: 'no',
      output_files: 'gerber only'
    }
  };
};

export const StencilSerializer = (reference: string) => {
  return {
    product_name: 'stencil',
    product_information: {
      reference: reference,
      provider_additional_comment: '',
      additional_comment: '',
      thickness: '0.1'
    }
  };
};

export const StudySerializer = (reference: string) => {
  return {
    product_name: 'study',
    product_information: {
      reference: reference,
      additional_comment: '',
      provider_additional_comment: '',
      special_specifications: '',
      study_and_development_costing_includes: '',
      costing_remarks:
        'The functionality of the card must be confirmed by a prototype. If there are any non-conformities, we will repair them on the prototype (no remanufacturing unless agreed), and modify the files.'
    }
  };
};

export const PCBSerializer = (reference: string) => {
  return {
    product_name: 'pcb',
    product_information: {
      additional_comment: '',
      provider_additional_comment: '',
      reference: reference,
      uom: 'mm',
      delivery_format: 'single',
      pcb_size_x: 100,
      pcb_size_y: 80,
      pcb_area: 0,
      pcb_quantity_x: 1,
      pcb_quantity_y: 1,
      pcb_separation_x: 'v-cut',
      pcb_separation_y: 'v-cut',
      space_border_left: 10,
      space_border_right: 10,
      space_border_top: 10,
      space_border_bottom: 10,
      pcb_separation_length_x: 2,
      pcb_separation_length_y: 2,
      array_size_x: 0,
      array_size_y: 0,
      array_area: 0,
      x_outs: 'no',
      multiple_design: 'no',
      cut_layers: 2,
      pcb_thickness: 1.6,
      base_material: 'fr4 tg135',
      final_cu_outer: 35,
      final_cu_inner: 35,
      surface_finish: 'hasl lead free',
      solder_mask: 'top + bottom',
      legend: 'top',
      mask_color: 'green',
      legend_color: 'white',
      rohs: 'yes',
      hdi: 'no',
      min_space: '>100um',
      min_drill_size: '>0.25mm',
      stackup: 'standard',
      min_line: '',
      ipc_class: '2',
      press_fit: 'no',
      etest_fixture: '',
      print_serial_number: 'no',
      etest_points_panel: '',
      led_order: '',
      mask_type: '',
      masking_two_x: '',
      via_resign_plug: 'no',
      via_masking: 'up to the gerber',
      control_dielectric: '',
      imped_ctrl: 'no',
      coupons: 'no',
      non_std_stackup_core: '',
      route_len_panel: 1,
      abnormal_hole: 'no',
      gold_finger_thickness: 0,
      non_std_stackup_pp: '',
      z_routing: 'no',
      counter_sunk: 'no',
      counter_sunk_quantity: '',
      edge_plating: 'no',
      number_of_edges: '',
      carbon_print: 'no',
      slots: 'no',
      hole_density: '<10k',
      gold_finger_quantity: 0,
      half_path: '',
      peelable_mask: 'no',
      micro_sec_report: 'no',
      destructive_report: '',
      solder_sample: 'no',
      low_matl_utilization: '',
      utilization_perc: '',
      total_number_drill_holes: '',
      coll_track_board: 'no',
      stencil: '',
      stencil_type: ''
    },
    manual_price: false
  };
};

export const gerberOption: Option[] = [
  {
    label: 'Gerber only',
    value: 'gerber only'
  },
  { label: 'Other', value: 'other' },
  { label: 'Others', value: 'others' }
];

export const pcbSeparationOption: Option[] = [
  { label: 'v-cut', value: 'v-cut' },
  { label: 'v-cut no border', value: 'v-cut no border' },
  { label: 'milling', value: 'milling' },
  { label: 'milling + stamps', value: 'milling + stamps' },
  { label: 'no separation', value: 'no separation' }
];

export const deliveryOption: Option[] = [
  { label: 'Single', value: 'single' },
  { label: 'Panel', value: 'panel' }
];

export const uomOption: Option[] = [
  { label: 'mm', value: 'mm' },
  { label: 'inch', value: 'inch' }
];

export const layerChoiseOption: Option[] = [
  { label: 'Silkscreen top', value: 'silkscreen top' },
  { label: 'Silkscreen bottom', value: 'silkscreen bottom' },
  { label: 'Soldermask top', value: 'soldermask top' },
  { label: 'Soldermask bottom', value: 'soldermask bottom' },
  { label: 'Copper layer top', value: 'copper layer top' },
  { label: 'Copper layer bottom', value: 'copper layer bottom' }
];

export const yesNoOption: Option[] = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' }
];

export const booleanOption: Option[] = [
  { label: 'Yes', value: true },
  { label: 'No', value: false }
];

export const drillSizeOption: Option[] = [
  { label: '> 0.25mm', value: '>0.25mm' },
  { label: '< 0.25mm', value: '<0.25mm' },
  { label: '< 0.20mm', value: '<0.20mm' },
  { label: '< 0.15', value: '<0.15mm' }
];

export const minSpaceOption: Option[] = [
  { label: '>100um', value: '>100um' },
  { label: '>85um', value: '>85um' },
  { label: '>75um', value: '>75um' },
  { label: '<75um', value: '<75um' }
];

export const stackupOption: Option[] = [
  { value: 'standard', label: 'standard' },
  { value: 'supplied', label: 'supplied' },
  { value: 'variable', label: 'variable (as per factory stocks)' }
];

export const IPCClassOption: Option[] = [
  { value: '2', label: '2' },
  { value: '3', label: '3' }
];

export const goldFingerThicknessOption: Option[] = [
  { label: 'No', value: null },
  { label: '10', value: 10 },
  { label: '15', value: 15 },
  { label: '20', value: 20 },
  { label: '30', value: 30 },
  { label: '40', value: 40 },
  { label: '50', value: 50 }
];

export const goldFingerOption: Option[] = [
  { label: 'No', value: null },
  { label: '<= 10u', value: 10 },
  { label: '<= 15u', value: 15 },
  { label: '<= 20u', value: 20 },
  { label: '<= 30u', value: 30 },
  { label: '<= 40u', value: 40 },
  { label: '<= 50u', value: 50 }
];

export const viaMaskingOption: Option[] = [
  { label: 'Up to the gerber', value: 'up to the gerber' },
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' }
];

export const hdiOption: Option[] = [
  { label: 'No', value: 'no' },
  { label: 'HDI 1+N+1', value: 'hdi 1+n+1' },
  { label: 'HDI 2+N+2', value: 'hdi 2+n+2' },
  { label: 'HDI 3+N+3', value: 'hdi 3+n+3' }
];

export const quotationStatusOption: Option[] = [
  { value: 'draft', label: 'Draft' },
  { value: 'rfq', label: 'RFQ' },
  { value: 'ready', label: 'Ready' },
  { value: 'sent', label: 'Sent' },
  { value: 'accepted', label: 'Accepted' },
  { value: 'closed', label: 'Closed' },
  { value: 'lost', label: 'Lost' }
];

export const rfqStatusOption: Option[] = [
  { value: 'draft', label: 'Draft' },
  { value: 'sent', label: 'Sent' },
  { value: 'done', label: 'Done' }
];

export const orderStatusOption: Option[] = [
  { value: 'draft', label: 'Draft' },
  { value: 'po sent', label: 'PO SENT' },
  { value: 'production', label: 'PRODUCTION' },
  { value: 'shipped', label: 'SHIPPED' },
  { value: 'partial delivery', label: 'PARTIAL DELIVERY' },
  { value: 'delivered', label: 'DELIVERED' },
  { value: 'invoiced', label: 'INVOICED' }
];

export const cuLayersOption: NumberOption[] = Array.from({ length: 64 }, (_, i) => ({
  value: i + 1,
  label: (i + 1).toString()
}));

export const pcbThicknessOption: NumberOption[] = [
  { value: 0.2, label: '0.2 mm' },
  { value: 0.4, label: '0.4 mm' },
  { value: 0.6, label: '0.6 mm' },
  { value: 0.8, label: '0.8 mm' },
  { value: 1.0, label: '1.0 mm' },
  { value: 1.2, label: '1.2 mm' },
  { value: 1.4, label: '1.4 mm' },
  { value: 1.6, label: '1.6 mm' },
  { value: 1.8, label: '1.8 mm' },
  { value: 2.0, label: '2.0 mm' },
  { value: 2.2, label: '2.2 mm' },
  { value: 2.4, label: '2.4 mm' },
  { value: null, label: 'NA' }
];

export const finalCuOption: NumberOption[] = [
  { value: 18, label: '18 um' },
  { value: 35, label: '35 um' },
  { value: 50, label: '50 um' },
  { value: 70, label: '70 um' },
  { value: 105, label: '105 um' },
  { value: 140, label: '140 um' },
  { value: 175, label: '175 um' },
  ...Array.from({ length: 7 }, (_, i) => ({ value: i * 35 + 210, label: i * 35 + 210 + ' um' }))
];

export const materialOption: Option[] = [
  { value: 'fr4 tg135', label: 'FR4 TG135' },
  { value: 'fr4 tg150', label: 'FR4 TG150' },
  { value: 'fr4 tg170', label: 'FR4 TG170' },
  { value: 'fr4 tg180', label: 'FR4 TG180' },
  { value: 'fr4 halogen free', label: 'FR4 Halogen Free' },
  { value: 'fr4 halogen free tg150', label: 'FR4 Halogen Free TG150' },
  { value: 'fr4 halogen free tg170', label: 'FR4 Halogen Free TG170' },
  { value: 'polyimide tg250', label: 'Polyimide TG250' },
  { value: 'roger ro4350', label: 'Roger RO4350' },
  { value: 'roger ro4350 lopro', label: 'Roger RO4350 LoPro' }
];

export const surfaceFinishOption: Option[] = [
  { value: 'hasl lead free', label: 'HASL Lead free' },
  { value: 'sn100c', label: 'SN100C' },
  { value: 'enig 2u', label: 'ENIG 2µm' },
  { value: 'enig 3u', label: 'ENIG 3µm' },
  { value: 'enig 4u', label: 'ENIG 4µm' },
  { value: 'immersion tin', label: 'Immersion tin' },
  { value: 'immersion silver', label: 'Immersion silver' },
  { value: 'hard gold', label: 'Hard gold' },
  { value: 'enepig', label: 'enepig' }
];

export const solderMaskAndLegendOption: Option[] = [
  { value: '', label: '__ (= rien)' },
  { value: 'top', label: 'top' },
  { value: 'bottom', label: 'bottom' },
  { value: 'top + bottom', label: 'top + bottom' }
];

export const maskColorOption: Option[] = [
  { value: 'green', label: 'Green' },
  { value: 'blue', label: 'Blue' },
  { value: 'red', label: 'Red' },
  { value: 'yellow', label: 'Yellow' },
  { value: 'black', label: 'Black' },
  { value: 'black matte', label: 'Black matte (verify spelling)' },
  { value: 'white', label: 'White' }
];

export const legendColorOption: Option[] = [
  { value: 'yellow', label: 'Yellow' },
  { value: 'black', label: 'Black' },
  { value: 'white', label: 'White' }
];

export const thicknessOption: Option[] = [
  { label: '0.1 mm', value: '0.1' },
  { label: '0.12 mm', value: '0.12' },
  { label: '0.15 mm', value: '0.15' },
  { label: '0.18 mm', value: '0.18' },
  { label: '0.2 mm', value: '0.2' },
  { label: 'Au choix du câbleur', value: 'Au choix du câbleur' },
  { label: 'NA', value: 'NA' }
];

export const taxeRatesOption: NumberOption[] = [
  { value: 0, label: '0' },
  { value: 0.05, label: '5' },
  { value: 0.1, label: '10' },
  { value: 0.15, label: '15' },
  { value: 0.2, label: '20' },
  { value: 0.25, label: '25' },
  { value: 0.3, label: '30' }
];

export const termPaymentOption: NumberOption[] = [
  { value: 0, label: '0' },
  { value: 5, label: '5' },
  { value: 10, label: '10' },
  { value: 15, label: '15' },
  { value: 20, label: '20' },
  { value: 25, label: '25' },
  { value: 30, label: '30' },
  { value: 35, label: '35' },
  { value: 40, label: '40' },
  { value: 45, label: '45' },
  { value: 50, label: '50' },
  { value: 55, label: '55' },
  { value: 60, label: '60' },
  { value: 65, label: '65' },
  { value: 70, label: '70' },
  { value: 75, label: '75' },
  { value: 80, label: '80' },
  { value: 85, label: '85' },
  { value: 90, label: '90' },
  { value: 95, label: '95' },
  { value: 100, label: '100' }
];

export const paymentMethodOption: Option[] = [
  { value: 'transfer', label: 'Transfer' },
  { value: 'check', label: 'Check' },
  { value: 'card', label: 'Card' }
];

export const paymentModalityOption: Option[] = [
  { value: 'net', label: 'Net' },
  { value: 'end of month on 10th', label: 'End of month on 10th' },
  { value: 'end of month 15', label: 'End of month 15' },
  { value: 'end of month 30', label: 'End of month 30' }
];

export const bankOption: Option[] = [
  { value: 'bnp_paribas', label: 'BNP Paribas' },
  { value: 'credit_agricole', label: 'Crédit Agricole' },
  { value: 'societe_generale', label: 'Société Générale' },
  { value: 'caisse_depargne', label: "Caisse d'Épargne" },
  { value: 'banque_postale', label: 'Banque Postale' },
  { value: 'credit_mutuel', label: 'Crédit Mutuel' },
  { value: 'banque_populaire', label: 'Banque Populaire' },
  { value: 'hsbc', label: 'HSBC ' },
  { value: 'cic', label: 'CIC' },
  { value: 'lcl', label: 'LCL' },
  { value: 'ing_direct', label: 'ING Direct' },
  { value: 'hello_bank', label: 'Hello bank!' },
  { value: 'boursorama', label: 'Boursorama' }
];

const StyledSelectContainer = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
`;

export const addressSelectedTemplate = (address: any) => {
  return (
    <StyledSelectContainer>
      <b>{address.address_name}</b>
      <div>{address.address_1}</div>
      <div>{address.postal_code + ', ' + address.city}</div>
      <div>{address.country}</div>
    </StyledSelectContainer>
  );
};

export const contactSelectedTemplate = (contact: Contact) => {
  return (
    <StyledSelectContainer>
      <div>{contact.last_name + ' ' + contact.first_name + ` (${contact.job_name})`}</div>
      <div>{contact.phone_number}</div>
      <div>{contact.email_address}</div>
    </StyledSelectContainer>
  );
};

export const updateQuotationDetails = (
  quotationDetail: Partial<Quotation>,
  patch: (url: string, obj: any) => Promise<AxiosResponse<any, any>>
) => {
  patch(
    patchQuotation(quotationDetail.company_id, quotationDetail.contact_id, quotationDetail.id),
    {
      ...quotationDetail
    }
  );
};

export const updateQuotationsContext = (
  quotations: QuotationsContextState,
  newQuotationDetail: Partial<Quotation>,
  dispatch: React.Dispatch<QuotationsDispatchAction>
) => {
  const updatedResults = quotations.results.map((quotationDetail) =>
    quotationDetail.id === newQuotationDetail.id ? newQuotationDetail : quotationDetail
  );

  if (!quotations.results.find((quotationDetail) => quotationDetail.id === newQuotationDetail.id)) {
    updatedResults.push(newQuotationDetail);
  }

  dispatch({
    type: 'UPDATE_DATA',
    payload: {
      ...quotations,
      count: updatedResults.length,
      results: updatedResults
    }
  });
};

export const useQuotationHandler = <T extends Option | NumberOption>() => {
  const { state: quotationState, dispatch: quotationAction } = useContext(
    QuotationsContext.Context
  );
  const { patch, post } = useApi();
  const navigate = useNavigate();
  const location = useLocation();

  const onChangeQuotationHandler =
    (
      field: string,
      quotationDetail: Partial<Quotation>,
      quotationLastVersionDetail: QuotationVersion,
      setterQuotationDetail?: React.Dispatch<any>,
      setter?: React.Dispatch<React.SetStateAction<T>>,
      currentContact?: Option
    ) =>
    async (newValue: Option | string) => {
      const changeContactId = (newContact: string) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('contactId', newContact);
        const newPath = `${location.pathname}?${searchParams.toString()}`;
        navigate(newPath);
      };

      const newQuotationDetail = {
        ...quotationDetail,
        [field]: newValue.value
      };

      if (setterQuotationDetail) {
        setterQuotationDetail(newQuotationDetail);
      }

      if (setter) {
        setter(newValue);
      }

      if (newQuotationDetail?.company_id && newQuotationDetail?.contact_id) {
        if (newQuotationDetail?.id) {
          const newQuotationVersions = newQuotationDetail.quotation_versions.map((version) =>
            version.id === quotationLastVersionDetail.id ? quotationLastVersionDetail : version
          );
          changeContactId(newQuotationDetail.contact_id.toString());
          const contact_id =
            currentContact.value !== undefined ? currentContact.value : currentContact;
          const newQuotation = await patch(
            patchQuotation(newQuotationDetail.company_id, contact_id, newQuotationDetail.id),
            {
              ...newQuotationDetail,
              quotation_versions: newQuotationVersions
            }
          );
          setterQuotationDetail(newQuotation.data);
          updateQuotationsContext(quotationState, newQuotationDetail, quotationAction);
        } else {
          const newQuotation = await post(
            postCreateQuotation(newQuotationDetail.company_id, newQuotationDetail.contact_id),
            {
              ...newQuotationDetail
            }
          );
          if (Object.keys(quotationLastVersionDetail).length) {
            const newQuotationVersion = await patch(
              patchQuotationVersion(
                newQuotationDetail.company_id,
                newQuotationDetail.contact_id,
                newQuotation.data.id,
                newQuotation.data.quotation_versions[0].id
              ),
              {
                ...quotationLastVersionDetail
              }
            );
            newQuotation.data.quotation_versions[0] = newQuotationVersion.data;
          }
          setterQuotationDetail(newQuotation.data);
          updateQuotationsContext(quotationState, newQuotation.data, quotationAction);
        }
      }
    };

  return onChangeQuotationHandler;
};

export const useQuotationVersionHandler = <T extends Option | NumberOption>() => {
  const { state: quotationState, dispatch: quotationAction } = useContext(
    QuotationsContext.Context
  );
  const { patch } = useApi();

  const onChangeQuotationVersionHandler =
    (
      field: string,
      quotationDetail: Partial<Quotation>,
      quotationLastVersionDetail: QuotationVersion,
      setterQuotationDetail?: React.Dispatch<any>,
      setter?: React.Dispatch<React.SetStateAction<T>>
    ) =>
    async (newValue: Option | string) => {
      const newQuotationLastVersionDetail = {
        ...quotationLastVersionDetail,
        [field]: newValue.value !== undefined ? newValue.value : newValue
      };

      if (setterQuotationDetail) {
        setterQuotationDetail({
          ...newQuotationLastVersionDetail,
          version_number: quotationLastVersionDetail.version_number
        });
      }

      if (setter) {
        setter(newValue);
      }

      if (newQuotationLastVersionDetail?.id) {
        if (quotationDetail?.company_id && quotationDetail?.contact_id && quotationDetail?.id) {
          delete newQuotationLastVersionDetail.version_number;
          patch(
            patchQuotationVersion(
              quotationDetail.company_id,
              quotationDetail.contact_id,
              quotationDetail.id,
              newQuotationLastVersionDetail.id
            ),
            {
              ...newQuotationLastVersionDetail
            }
          );

          const newQuotationDetail: Partial<Quotation> = {
            ...quotationDetail,
            quotation_versions: quotationDetail.quotation_versions.map((item) =>
              item.id === newQuotationLastVersionDetail.id ? newQuotationLastVersionDetail : item
            )
          };

          updateQuotationsContext(quotationState, newQuotationDetail, quotationAction);
        }
      }
    };

  return onChangeQuotationVersionHandler;
};

export const useOrderHandler = <T extends Option | NumberOption>() => {
  const { patch } = useApi();

  const onChangeOrderHandler =
    (
      field: string,
      order: Partial<Order>,
      setterOrder?: React.Dispatch<any>,
      setter?: React.Dispatch<React.SetStateAction<T>>
    ) =>
    async (newValue: Option | string) => {
      const newOrder = {
        ...order,
        [field]: newValue.value !== undefined ? newValue.value : newValue
      };

      if (setter) {
        setter(newValue);
      }

      if (setterOrder) {
        setterOrder({
          ...newOrder
        });
      }

      patch(patchOrder(order.id), {
        ...newOrder
      });
    };

  return onChangeOrderHandler;
};

import React from 'react';

const Eye = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.935 8.74994C13.615 8.19494 11.855 5.40994 8.86503 5.49994C6.10003 5.56994 4.50003 7.99994 4.06503 8.74994C3.97572 8.90464 3.97572 9.09524 4.06503 9.24994C4.38003 9.79494 6.06503 12.4999 9.01003 12.4999H9.13503C11.9 12.4299 13.505 9.99994 13.935 9.24994C14.0244 9.09524 14.0244 8.90464 13.935 8.74994ZM9.00003 10.7499C8.03354 10.7499 7.25003 9.96643 7.25003 8.99994C7.25003 8.03344 8.03354 7.24994 9.00003 7.24994C9.96653 7.24994 10.75 8.03344 10.75 8.99994C10.75 9.96643 9.96653 10.7499 9.00003 10.7499ZM9.00003 9.74994C9.41425 9.74994 9.75003 9.41415 9.75003 8.99994C9.75003 8.58572 9.41425 8.24994 9.00003 8.24994C8.58582 8.24994 8.25003 8.58572 8.25003 8.99994C8.25003 9.41415 8.58582 9.74994 9.00003 9.74994Z"
        fill="#1975FF"
      />
    </svg>
  );
};

export default Eye;

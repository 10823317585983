import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Download } from 'svgs';
import { AdminsContext, ProvidersContactContext } from 'contexts';
import { ButtonVariant } from 'components/common/Button';
import { Button, SmallLoader } from 'components/common';
import { BlueBoldTextContainer } from 'utils/array';
import useApi from 'services/axios';
import { getProvidersContact } from 'services/endpoints';

import { Array, clientProvidersContact } from '../array';
import { MainContainer, Title } from '../styledComponent';
import PictureTag from '../PictrureTag';
import { Overflow, capitalizeFirstLetter, getInitial } from '../Tools';

const ProvidersContact: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { state: providersContactState, dispatch: providersContactAction } = useContext(
    ProvidersContactContext.Context
  );
  const { state: adminsState } = useContext(AdminsContext.Context);
  const navigate = useNavigate();
  const { get } = useApi();

  const getAdminInital = (adminId: number) => {
    const admin = adminsState.find((admin: Admin) => admin.id === adminId);

    if (admin) {
      return getInitial(admin.first_name, admin.last_name);
    }

    return `__`;
  };

  const getPhoneNumberFormatted = (phoneNumber: string | null) => {
    if (phoneNumber === null) {
      return '';
    }
    return phoneNumber.replace(/.{2}/g, '$& ');
  };

  const providersContactPreProcessing = providersContactState.results.map((contact) => ({
    id: `${contact.provider_company_id}-${contact.id}`,
    company_name: {
      value: contact.company_name,
      display: <div>{capitalizeFirstLetter(contact.company_name)}</div>
    },
    last_name: {
      value: contact.last_name,
      display: <div>{capitalizeFirstLetter(contact.last_name)}</div>
    },
    first_name: {
      value: contact.first_name,
      display: <div>{capitalizeFirstLetter(contact.first_name)}</div>
    },
    email_address: {
      value: contact.email_address,
      display: <BlueBoldTextContainer>{contact.email_address}</BlueBoldTextContainer>
    },
    phone_number: {
      value: contact.phone_number,
      display: <div>{getPhoneNumberFormatted(contact.phone_number)}</div>
    },
    sales_person: {
      value: getAdminInital(contact.sales_person),
      display: <PictureTag text={getAdminInital(contact.sales_person)} imgUrl="" />
    }
  }));

  const goToContact = (event: React.MouseEvent<HTMLTableRowElement>) => {
    const id = event.currentTarget.id.split('-')[1];
    navigate(`/Clients-Providers/Provider?providerId=${id}&providerView=contacts`);
  };

  const updateProviderContact = (newPage: any) => {
    providersContactAction({
      type: 'UPDATE_DATA',
      payload: newPage
    });
  };

  const openNewWindowButtonClick = (id: string) => {
    const values = id.split('-');
    window.open(
      `/Clients-Providers/Provider?providerId=${values[0]}&providerView=contacts`,
      '_blank'
    );
  };

  useEffect(() => {
    (async () => {
      const contact = await get(getProvidersContact);
      providersContactAction({ type: 'SET_DATA', payload: contact.data });
      setIsLoading(false);
    })();
  }, []);

  if (isLoading) {
    return <SmallLoader />;
  }

  return (
    <MainContainer>
      <Title>Providers contacts</Title>
      <Overflow>
        <Array
          template={clientProvidersContact}
          content={providersContactPreProcessing}
          lineClicked={goToContact}
          pagination={providersContactState}
          updatedArrayData={updateProviderContact}
          url={getProvidersContact}
          newWindowUrl={openNewWindowButtonClick}
          tools={<Button variant={ButtonVariant.PRIMARY} text="Export" leftIcon={Download()} />}
        />
      </Overflow>
    </MainContainer>
  );
};

export default ProvidersContact;

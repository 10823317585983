import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';

import { Button, ButtonSizing, ButtonVariant, SmallLoader } from 'components/common';
import { Download, Eye, Trash } from 'svgs';
import useApi from 'services/axios';
import { delDocument, getDocument, postDocument } from 'services/endpoints';

const DocumentListContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  gap: 8px;
  border-bottom: 1px solid #e0e0e0;
`;

const DocumentName = styled.span`
  word-break: break-all;
  flex-grow: 1;
  font-size: 14px;
  color: #333;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;
`;

type GeneralDocumentType = 'quotation-main-document' | 'customer-order-main-document';

type GeneralDocumentsProps = {
  quotation: Partial<Quotation>;
  documentType: GeneralDocumentType;
  setQuotation: React.Dispatch<React.SetStateAction<Partial<Quotation>>>;
};

const GeneralDocuments: React.FC<GeneralDocumentsProps> = ({
  quotation,
  documentType,
  setQuotation
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { post, get, del } = useApi();

  const onDrop = React.useCallback(
    async (acceptedFiles: File[], documentType: GeneralDocumentType) => {
      const newDocuments = await Promise.all(
        acceptedFiles.map(async (file) => {
          const formData = new FormData();
          formData.append('file', file, file.name);
          formData.append('document_type', documentType);
          formData.append('instance_id', quotation?.id?.toString());

          const newDoc = await post(postDocument(), formData);
          return newDoc.data;
        })
      );

      setQuotation((prevItem) => {
        return {
          ...prevItem,
          documents: {
            ...prevItem?.documents,
            [documentType]: [...(prevItem?.documents[documentType] || []), ...newDocuments]
          }
        };
      });
    },
    [quotation]
  );

  const handlePreview = async (documentId: number, documentName: string) => {
    const response = await get(getDocument(documentId), 'blob');

    const url = window.URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = url;
    link.download = documentName;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const handleDelete = async (documentId: number, documentType: GeneralDocumentType) => {
    setQuotation((prevItem) => {
      const updatedDocuments = prevItem?.documents[documentType]?.filter(
        (doc) => doc.document_id !== documentId
      );

      return {
        ...prevItem,
        documents: { ...prevItem?.documents, [documentType]: updatedDocuments }
      };
    });

    await del(delDocument(documentId));
  };

  const getDocumentTypeDescription = (documentType: GeneralDocumentType): string => {
    switch (documentType) {
      case 'quotation-main-document':
        return 'Document Devis';
      case 'customer-order-main-document':
        return 'Document Commande';
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles) => {
      setIsLoading(true);
      await onDrop(acceptedFiles, documentType);
      setIsLoading(false);
    }
  });

  return (
    <div>
      <div
        {...getRootProps()}
        style={{
          border: '2px dashed #1975FF',
          padding: '20px',
          textAlign: 'center',
          width: '400px'
        }}
      >
        {isLoading ? (
          <SmallLoader />
        ) : (
          <>
            <input {...getInputProps()} />
            <p
              style={{
                color: '#185FC9',
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '8px'
              }}
            >
              {getDocumentTypeDescription(documentType)}
              {Download()}
            </p>
          </>
        )}
      </div>
      <div>
        {quotation?.documents?.[documentType]?.map((doc) => (
          <DocumentListContainer key={doc.document_id}>
            <DocumentName>{doc.uploaded_date}</DocumentName>
            <DocumentName>{doc.file_name}</DocumentName>
            <ButtonGroup>
              <Button
                variant={ButtonVariant.PRIMARY_LIGHT}
                leftIcon={Eye()}
                sizing={ButtonSizing.SMALL}
                onClick={() => handlePreview(doc.document_id, doc.file_name)}
              />
              <Button
                variant={ButtonVariant.ERROR}
                leftIcon={Trash()}
                sizing={ButtonSizing.ICON_OPTION}
                onClick={() => handleDelete(doc.document_id, documentType)}
              />
            </ButtonGroup>
          </DocumentListContainer>
        ))}
      </div>
    </div>
  );
};

export default GeneralDocuments;

import React, { forwardRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { Button, ButtonSizing, ButtonVariant, Checkbox } from 'components/common';
import { ArrowDown, DotDotDot } from 'svgs';

enum SortingState {
  Ascendant = 'ascendant',
  Descendant = 'descendant',
  Neutral = 'neutral'
}

const Cell = styled.th`
  padding: ${(props) => props.theme.spacing.xs}px;
  gap: ${(props) => props.theme.spacing.xxxs}px;
  text-align: start;
  border-bottom: 1px solid ${(props) => props.theme.colors.primary.lighter};

  cursor: pointer;
  color: ${(props) => props.theme.colors.text.primary};
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
`;

const Extended = styled.td`
  padding: 16px 32px;
`;

const ErrorLineMessage = styled.td`
  padding: 8px 32px;
  background: red;
`;

const Arrow = styled.div<{
  isOpen: boolean;
}>`
  display: inline-flex;
  transform: ${(props) => (props.isOpen ? `rotate(0deg)` : `rotate(180deg)`)};
  transition: transform 0.175s ease-out;
`;

const CustomBackground = styled.tr`
  background: #f0f0f06b;
`;

const Container = styled.tr<{
  background: string;
}>`
  background: ${(props) => (props.background ? props.background : 'transparent')};
`;

type BodyProps = {
  isSelected: boolean;
  template: Column[];
  content: any;
  lineClicked?: (event: React.MouseEvent<HTMLTableRowElement>) => void;
  lineSelected?: (id: number, isUnSelected?: boolean) => void;
  ref?: (element: HTMLElement) => void;
  newWindowUrl?: (id: string) => void;
  extended?: boolean;
  errorLineMessage?: boolean;
  sorted?: boolean;
  checkboxDisplayed: boolean;
  checkedElements?: number[];
  sortingState?: ColumnSortingState;
  provided?: any;
  snapshot?: any;
  isDragDisabled?: boolean;
  handleDragAndDrop?: (newOrder: any) => void;
  moreOptions?: JSX.Element[];
};

const Content = forwardRef<HTMLTableRowElement, BodyProps>(
  (
    {
      isSelected,
      lineClicked,
      lineSelected,
      content,
      template,
      extended,
      errorLineMessage,
      checkboxDisplayed,
      provided,
      newWindowUrl,
      moreOptions
    },
    ref
  ) => {
    const [isCheckboxSelected, setIsCheckboxSelected] = useState(false);
    const [isExtendedDisplayed, setIsExtendedDisplayed] = useState(false);
    const [contentId, setContentId] = useState<string | null>(null);
    const [isTooltipDisplayed, setIsTooltipDisplayed] = useState<boolean>(null);

    useEffect(() => {
      setIsCheckboxSelected(isSelected);
    }, [isSelected]);

    const handleCheckboxClicked = (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      setIsCheckboxSelected((value) => !value);
      lineSelected(content.id, isCheckboxSelected);
    };

    const handleDropdownClicked = (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      setIsExtendedDisplayed((value) => !value);
    };

    const handleOptionButtonClicked = (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      location.hash = `content_id=${content.id}`;
      setContentId(content.id);
    };

    const handleContextMenu = (event: React.MouseEvent<HTMLTableRowElement>) => {
      if (newWindowUrl && (event.ctrlKey || event.metaKey)) {
        newWindowUrl(content.id);
      }
    };

    useEffect(() => {
      const hash = location.hash.substring(1);
      const params = new URLSearchParams(hash);
      const hashContentId = params.get('content_id');
      setIsTooltipDisplayed((hashContentId || contentId) == content.id);
    }, [location.hash]);

    return (
      <>
        <Container
          ref={ref}
          id={`tr-${content.id}`}
          onClick={lineClicked}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          background={content?.background}
          onContextMenu={handleContextMenu}
        >
          {checkboxDisplayed && (
            <Cell>
              <Checkbox
                key={`checkbox-${content.id}`}
                isSelected={isCheckboxSelected}
                onClick={handleCheckboxClicked}
              />
            </Cell>
          )}
          {template.map((template, templateIndex) => {
            if (template.property === 'Options') {
              return (
                <Cell
                  key={`content-${content.id}-${templateIndex}`}
                  onClick={handleOptionButtonClicked}
                >
                  <Button
                    variant={ButtonVariant.ICON_OPTION}
                    leftIcon={DotDotDot()}
                    sizing={ButtonSizing.ICON_OPTION}
                    tooltips={moreOptions}
                    isTooltipsDisplayed={isTooltipDisplayed}
                  />
                </Cell>
              );
            }
            if (template.property === 'Dropdown') {
              return (
                <Cell
                  key={`content-${content.id}-${templateIndex}`}
                  onClick={handleDropdownClicked}
                >
                  <Arrow isOpen={isExtendedDisplayed}>
                    <ArrowDown />
                  </Arrow>
                </Cell>
              );
            }
            return (
              <Cell key={`content-${content.id}-${templateIndex}`}>
                {content[template.property].display}
              </Cell>
            );
          })}
        </Container>
        {errorLineMessage && content?.errorLineMessage && (
          <ErrorLineMessage colSpan={template.length + 1}>
            {content.errorLineMessage}
          </ErrorLineMessage>
        )}
        {extended && isExtendedDisplayed && (
          <CustomBackground>
            <Extended colSpan={template.length + 1}>{content.extended}</Extended>
          </CustomBackground>
        )}
      </>
    );
  }
);

Content.displayName = 'Content';

type ContentType = {
  [key: string]: {
    value: any;
  };
};

const Body = ({
  isSelected,
  content,
  template,
  lineClicked,
  lineSelected,
  extended,
  errorLineMessage,
  sorted,
  checkboxDisplayed,
  checkedElements,
  sortingState,
  isDragDisabled,
  handleDragAndDrop,
  newWindowUrl,
  moreOptions
}: BodyProps) => {
  const getSortedContent = (): ContentType[] => {
    const normalizeValue = (value: string | number) => {
      if (typeof value === 'string') {
        const numberValue = Number(value);
        return isNaN(numberValue) ? value.toLowerCase() : numberValue;
      }
      return value;
    };

    const compareContent = Object.keys(sortingState)
      .filter((key) => sortingState[key] !== SortingState.Neutral)
      .map((key) => (a: ContentType, b: ContentType) => {
        const valueA = normalizeValue(a[key]?.value);
        const valueB = normalizeValue(b[key]?.value);

        if (valueA === valueB) return 0;
        return sortingState[key] === SortingState.Ascendant
          ? valueA < valueB
            ? -1
            : 1
          : valueA > valueB
          ? -1
          : 1;
      });

    return compareContent.length
      ? [...content].sort((a, b) =>
          compareContent.reduce((acc, compare) => acc || compare(a, b), 0)
        )
      : [...content];
  };

  const sortedContent = sorted ? getSortedContent() : content;

  const handleOnDragEnd = async (result: any) => {
    if (!result.destination || !handleDragAndDrop) return;

    const items = Array.from(sortedContent);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    handleDragAndDrop(items);
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="table">
        {(provided) => (
          <tbody {...provided.droppableProps} ref={provided.innerRef}>
            {sortedContent.map((result: any, index: number) => (
              <Draggable
                key={`drag-${result.id}-${index}`}
                draggableId={`drag-${result.id}-${index}`}
                index={index}
                isDragDisabled={isDragDisabled}
                disableInteractiveElementBlocking={false}
              >
                {(provided, snapshot) => (
                  <Content
                    ref={provided.innerRef}
                    provided={provided}
                    snapshot={snapshot}
                    isSelected={isSelected || !!checkedElements?.includes(result.id)}
                    content={result}
                    template={template}
                    lineClicked={lineClicked}
                    lineSelected={lineSelected}
                    extended={extended}
                    errorLineMessage={errorLineMessage}
                    checkboxDisplayed={checkboxDisplayed}
                    moreOptions={moreOptions}
                    newWindowUrl={newWindowUrl}
                  />
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </tbody>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default Body;

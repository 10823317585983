import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { debounce } from 'lodash';

import useApi from 'services/axios';
import {
  delQuotationItem,
  getQuotationItems,
  getRequestForQuotation,
  patchItemRankOrder,
  patchQuotationItem,
  postCreateQuotationItem,
  postDuplicateQuotationItem
} from 'services/endpoints';
import { AllProvidersContext, CurrencyContext } from 'contexts';
import { Button, CustomInput, CustomSelect } from 'components/common';
import { getSelectStyles } from 'utils/style';
import { booleanOption } from 'utils/sales';
import { QuotationReferenceModal } from 'components/modal';
import { ButtonSizing, ButtonVariant } from 'components/common/Button';
import { getValue } from 'components/Tools';
import { Copy, Trash, WhiteCross } from 'svgs';

import { Array, quotationItems } from '../array';

import ItemExtended from './ItemExtended';

const ButtonContainer = styled.div`
  position: sticky;
  left: 0;
  display: flex;
  gap: 8px;
`;

const CustomSelectManualPrice: React.FC<{
  productTypeOptions: Option[];
  quotationItem: QuotationItem;
  quotationItems: QuotationItem[];
  contactId: number;
  setItems: React.Dispatch<React.SetStateAction<QuotationItem[]>>;
  isOldQuotationVersion: boolean;
}> = ({
  productTypeOptions,
  quotationItem,
  quotationItems,
  contactId,
  setItems,
  isOldQuotationVersion
}) => {
  const { patch } = useApi();
  const [manualPrice, setManualPrice] = useState<Option>(
    productTypeOptions.find((product) => product.value === quotationItem?.manual_price)
  );

  const handleChange = async (e: Option) => {
    setManualPrice(e);

    const newQuotationItem = await patch(
      patchQuotationItem(
        quotationItem.company,
        contactId,
        quotationItem.quotation,
        quotationItem.quotation_version,
        quotationItem.id
      ),
      {
        ...quotationItem,
        manual_price: e.value
      }
    );

    const newItems = quotationItems.map((quotationItems) =>
      quotationItems.id === quotationItem.id ? newQuotationItem.data : quotationItems
    );

    setItems(newItems);
  };

  return (
    <CustomSelect
      placeholder="PCB"
      value={manualPrice}
      options={productTypeOptions}
      onChange={(e: Option) => {
        handleChange(e);
      }}
      isMulti={false}
      isSearchable={isOldQuotationVersion}
      isDisabled={isOldQuotationVersion}
      styles={getSelectStyles(null, '150px', '50px')}
      usePortalStyles={true}
    />
  );
};

const CustomRFQSelectProcessing: React.FC<{
  quotationItem: QuotationItem;
  quotationItems: QuotationItem[];
  contactId: number;
  setItems: React.Dispatch<React.SetStateAction<QuotationItem[]>>;
  setGlobalRfq: React.Dispatch<React.SetStateAction<RfqContextState>>;
  globalRfq: RfqContextState;
  isOldQuotationVersion: boolean;
  providerFiltered: number;
}> = ({
  quotationItem,
  quotationItems,
  contactId,
  setItems,
  globalRfq,
  setGlobalRfq,
  isOldQuotationVersion,
  providerFiltered
}) => {
  const { patch, get } = useApi();

  const [rfqPreProcessing, setRfqPreProcessing] = useState<Option[]>([]);
  const [newRfq, setRfq] = useState<Option>();

  const handleChange = async (e: Option) => {
    setRfq(e);

    const newQuotationItem = await patch(
      patchQuotationItem(
        quotationItem.company,
        contactId,
        quotationItem.quotation,
        quotationItem.quotation_version,
        quotationItem.id
      ),
      {
        ...quotationItem,
        rfq: e.value
      }
    );

    const newItems = quotationItems.map((quotationItems) =>
      quotationItems.id === quotationItem.id ? newQuotationItem.data : quotationItems
    );

    setItems(newItems);
  };

  const handleSelectClick = async () => {
    const rfqsResult = await get(getRequestForQuotation + `?company_id=${quotationItem.company}`);
    setGlobalRfq(rfqsResult.data);
    setRfqPreProcessing(
      rfqsResult.data?.results
        .filter((item: Rfq) => item.provider_id === providerFiltered)
        .map((item: Rfq) => {
          return {
            label: item.reference,
            value: item.id
          };
        })
    );
  };

  useEffect(() => {
    setRfq(rfqPreProcessing?.find((item: Option) => item.value === quotationItem.rfq) || null);
    setRfqPreProcessing(
      globalRfq?.results
        .filter((item: Rfq) => item.provider_id === providerFiltered)
        .map((item: Rfq) => {
          return {
            label: item.reference,
            value: item.id
          };
        })
    );
  }, [quotationItem]);

  return (
    <CustomSelect
      placeholder="RFQ"
      value={newRfq}
      options={rfqPreProcessing}
      onMenuOpen={handleSelectClick}
      onChange={(e: Option) => {
        handleChange(e);
      }}
      isMulti={false}
      isSearchable={true}
      isDisabled={!providerFiltered || isOldQuotationVersion}
      styles={getSelectStyles(null, '150px', '50px')}
      usePortalStyles={true}
    />
  );
};

const CustomProvidersSelectCurrency: React.FC<{
  quotationItem: QuotationItem;
  quotationItems: QuotationItem[];
  contactId: number;
  setItems: React.Dispatch<React.SetStateAction<QuotationItem[]>>;
  isOldQuotationVersion: boolean;
}> = ({ quotationItem, quotationItems, contactId, setItems, isOldQuotationVersion }) => {
  const { state: currencyState } = useContext(CurrencyContext.Context);
  const { patch } = useApi();

  const currencyOption = currencyState.results.map((curr: Currency) => {
    return {
      label: curr.name,
      value: curr.id
    };
  });

  const [currency, setCurrency] = useState<Option>(
    currencyOption.find((curr) => curr.value === quotationItem.provider_currency)
  );

  const handleChange = async (e: Option) => {
    setCurrency(e);

    const newQuotationItem = await patch(
      patchQuotationItem(
        quotationItem.company,
        contactId,
        quotationItem.quotation,
        quotationItem.quotation_version,
        quotationItem.id
      ),
      {
        ...quotationItem,
        provider_currency: e.value
      }
    );

    const newItems = quotationItems.map((quotationItems) =>
      quotationItems.id === quotationItem.id ? newQuotationItem.data : quotationItems
    );

    setItems(newItems);
  };

  useEffect(() => {
    setCurrency(currencyOption.find((curr) => curr.value === quotationItem.provider_currency));
  }, [quotationItem]);

  return (
    <CustomSelect
      placeholder="Currency"
      value={currency}
      options={currencyOption}
      onChange={(e: Option) => {
        handleChange(e);
      }}
      isMulti={false}
      isSearchable={isOldQuotationVersion}
      isDisabled={isOldQuotationVersion}
      styles={getSelectStyles(null, '150px', '50px')}
      usePortalStyles={true}
    />
  );
};

const CustomProvidersSelectProcessing: React.FC<{
  quotationItem: QuotationItem;
  quotationItems: QuotationItem[];
  contactId: number;
  setItems: React.Dispatch<React.SetStateAction<QuotationItem[]>>;
  isOldQuotationVersion: boolean;
}> = ({ quotationItem, quotationItems, contactId, setItems, isOldQuotationVersion }) => {
  const { state: allProvidersState } = useContext(AllProvidersContext.Context);
  const { patch } = useApi();

  const providerPreProcessing = allProvidersState.map((provider) => {
    return {
      label: provider.company_name,
      value: provider.id
    };
  });

  const [provider, setProviders] = useState<Option>(
    providerPreProcessing.find((product) => product.value === quotationItem.provider)
  );

  useEffect(() => {
    setProviders(providerPreProcessing.find((product) => product.value === quotationItem.provider));
  }, [quotationItem]);

  const handleChange = async (e: Option) => {
    setProviders(e);

    const newQuotationItem = await patch(
      patchQuotationItem(
        quotationItem.company,
        contactId,
        quotationItem.quotation,
        quotationItem.quotation_version,
        quotationItem.id
      ),
      {
        ...quotationItem,
        provider: e.value,
        rfq: null
      }
    );

    const newItems = quotationItems.map((quotationItems) =>
      quotationItems.id === quotationItem.id ? newQuotationItem.data : quotationItems
    );

    setItems(newItems);
  };

  return (
    <CustomSelect
      placeholder="Provider"
      value={provider}
      options={providerPreProcessing}
      onChange={(e: Option) => {
        handleChange(e);
      }}
      isMulti={false}
      isSearchable={isOldQuotationVersion}
      isDisabled={isOldQuotationVersion}
      styles={getSelectStyles(null, '150px', '50px')}
      usePortalStyles={true}
    />
  );
};

const CustomQuantityProcessing: React.FC<{
  quotationItem: QuotationItem;
  quotationItems: QuotationItem[];
  contactId: number;
  setItems: React.Dispatch<React.SetStateAction<QuotationItem[]>>;
  isOldQuotationVersion: boolean;
}> = ({ quotationItem, quotationItems, contactId, setItems, isOldQuotationVersion }) => {
  const { patch } = useApi();
  const [value, setValue] = useState<number>(quotationItem?.quantity);

  useEffect(() => {
    setValue(quotationItem.quantity);
  }, [quotationItem]);

  const handleChange = () => async (value: number) => {
    setValue(value);

    const newQuotationItem = await patch(
      patchQuotationItem(
        quotationItem.company,
        contactId,
        quotationItem.quotation,
        quotationItem.quotation_version,
        quotationItem.id
      ),
      {
        ...quotationItem,
        quantity: value
      }
    );

    const newItems = quotationItems.map((quotationItems) =>
      quotationItems.id === quotationItem.id ? newQuotationItem.data : quotationItems
    );

    setItems(newItems);
  };

  return (
    <CustomInput
      isDisabled={isOldQuotationVersion}
      defaultValue={value}
      placeholder="00"
      callBack={() => handleChange()}
      width="80px"
      type="number"
      debounceTime={1000}
    />
  );
};

type ItemsProps = {
  quotationDetails: Partial<Quotation>;
  isOldQuotationVersion: boolean;
  currentContact: number;
  quotationVersion?: number;
  isNewQuotation?: boolean;
};

const Items: React.FC<ItemsProps> = ({
  quotationDetails,
  quotationVersion,
  currentContact,
  isOldQuotationVersion
}) => {
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [isItemsLoading, setIsItemsLoading] = useState<boolean>(true);
  const [itemsPreProcessing, setItemsPreProcessing] = useState([]);
  const [items, setItems] = useState<QuotationItem[]>([]);
  const [contactId, setContactId] = useState<number>(currentContact);
  const [, setDebounceInput] = useState<DebounceInputState>([]);
  const [item, setItem] = useState<QuotationItem>();
  const [rfq, setRfq] = useState<RfqContextState>();
  const [referenceModalDisplayed, setReferenceModalDisplayed] = useState(false);

  const { del, get, post, patch } = useApi();
  const { is_order_created } = quotationDetails;

  const handleReferenceClick = (item: QuotationItem) => {
    setItem(item);
    setReferenceModalDisplayed(true);
  };

  const onCloseReferenceModal = () => setReferenceModalDisplayed(false);

  const debouncedPatchesRef = useRef<{ [key: number]: ReturnType<typeof debounce> }>({});

  const getDebouncedPatch = (id: number) => {
    if (!debouncedPatchesRef.current[id]) {
      debouncedPatchesRef.current[id] = debounce(
        async (
          itemDetails: QuotationItem,
          itemName: string,
          itemValue: string | number,
          currentDebounceInput: Partial<QuotationItem>
        ) => {
          try {
            const newQuotationItem = await patch(
              patchQuotationItem(
                itemDetails.company,
                contactId,
                itemDetails.quotation,
                itemDetails.quotation_version,
                itemDetails.id
              ),
              {
                ...itemDetails,
                ...currentDebounceInput,
                [itemName]: itemValue
              }
            );
            setItems((prevItems) =>
              prevItems.map((item) => (item.id === itemDetails.id ? newQuotationItem.data : item))
            );
          } catch (error) {
            console.error('Could not update item', error);
          }
        },
        300
      );
    }
    return debouncedPatchesRef.current[id];
  };

  const handleChange = (name: string, details: QuotationItem) => (value: string | number) => {
    setDebounceInput((prevState) => {
      const newState = {
        ...prevState,
        [details?.id]: {
          ...prevState[details?.id],
          [name]: value
        }
      };
      const debouncedPatch = getDebouncedPatch(details.id);
      debouncedPatch(details, name, value, newState[details.id]);
      return newState;
    });
  };

  const handleItemUpdate = (
    updater: (
      prevItem: QuotationItem | OrderItem | ProviderPurchaseItem
    ) => QuotationItem | OrderItem | ProviderPurchaseItem
  ) => {
    setItems((prevItems) =>
      prevItems.map((item) => {
        const updatedItem = updater(item);
        return item.id === updatedItem.id ? (updatedItem as QuotationItem) : item;
      })
    );
  };

  useEffect(() => {
    if (!referenceModalDisplayed) {
      const newItemsPreProcessing = items.map((item) => {
        return {
          id: item?.id,
          product_name: {
            value: item?.item_rendered?.product_name,
            display: (
              <div>
                {item?.item_rendered?.product_name ? item?.item_rendered?.product_name : '-'}
              </div>
            )
          },
          reference: {
            value: item?.item_rendered?.product_information?.reference,
            display: (
              <CustomInput
                isDisabled={isOldQuotationVersion}
                defaultValue={item?.item_rendered?.product_information?.reference}
                placeholder="reference"
                onClick={() => handleReferenceClick(item)}
              />
            )
          },
          internal_reference: {
            value: item?.item_rendered?.internal_reference,
            display: (
              <div>
                {item?.item_rendered?.internal_reference
                  ? item?.item_rendered?.internal_reference
                  : '-'}
              </div>
            )
          },
          provider: {
            value: 'undefined',
            display: (
              <CustomProvidersSelectProcessing
                quotationItem={item}
                quotationItems={items}
                contactId={contactId}
                setItems={setItems}
                isOldQuotationVersion={isOldQuotationVersion}
              />
            )
          },
          RFQ: {
            value: 'undefined',
            display: (
              <CustomRFQSelectProcessing
                quotationItem={item}
                quotationItems={items}
                contactId={contactId}
                setItems={setItems}
                setGlobalRfq={setRfq}
                globalRfq={rfq}
                isOldQuotationVersion={isOldQuotationVersion}
                providerFiltered={item?.provider}
              />
            )
          },
          manual_price: {
            value: item?.manual_price,
            display: (
              <CustomSelectManualPrice
                productTypeOptions={booleanOption}
                quotationItem={item}
                contactId={contactId}
                quotationItems={items}
                setItems={setItems}
                isOldQuotationVersion={isOldQuotationVersion}
              />
            )
          },
          quantity: {
            value: item?.quantity,
            display: (
              <CustomQuantityProcessing
                quotationItem={item}
                quotationItems={items}
                contactId={contactId}
                setItems={setItems}
                isOldQuotationVersion={isOldQuotationVersion}
              />
            )
          },
          unit_cost: {
            value: item?.unit_cost,
            display: (
              <CustomInput
                isDisabled={isOldQuotationVersion}
                defaultValue={item?.unit_cost}
                placeholder="00"
                callBack={() => handleChange('unit_cost', item)}
                width="80px"
                type="number"
                debounceTime={500}
              />
            )
          },
          tooling_costs: {
            value: item?.tooling_costs,
            display: (
              <CustomInput
                isDisabled={isOldQuotationVersion}
                defaultValue={item?.tooling_costs}
                placeholder="00"
                callBack={() => handleChange('tooling_costs', item)}
                width="80px"
                type="number"
                debounceTime={500}
              />
            )
          },
          other_costs: {
            value: item?.other_costs,
            display: (
              <CustomInput
                isDisabled={isOldQuotationVersion}
                defaultValue={item?.other_costs}
                placeholder="00"
                callBack={() => handleChange('other_costs', item)}
                width="80px"
                type="number"
                debounceTime={500}
              />
            )
          },
          tax_rate: {
            value: item?.tax_rate,
            display: (
              <CustomInput
                isDisabled={isOldQuotationVersion}
                defaultValue={item?.tax_rate}
                placeholder="00"
                callBack={() => handleChange('tax_rate', item)}
                width="80px"
                type="number"
                debounceTime={500}
              />
            )
          },
          provider_currency: {
            value: item?.change_rate,
            display: (
              <CustomProvidersSelectCurrency
                quotationItem={item}
                contactId={contactId}
                quotationItems={items}
                setItems={setItems}
                isOldQuotationVersion={isOldQuotationVersion}
              />
            )
          },
          change_rate: {
            value: item?.change_rate,
            display: <div>{getValue(item?.change_rate?.toString())}</div>
          },
          shipping_costs: {
            value: item?.shipping_costs,
            display: (
              <CustomInput
                isDisabled={isOldQuotationVersion}
                defaultValue={item?.shipping_costs}
                placeholder="00"
                callBack={() => handleChange('shipping_costs', item)}
                width="80px"
                type="number"
                debounceTime={500}
              />
            )
          },
          margin_rate: {
            value: item?.margin_rate,
            display: (
              <CustomInput
                isDisabled={isOldQuotationVersion}
                defaultValue={item?.margin_rate}
                placeholder="00"
                callBack={() => handleChange('margin_rate', item)}
                width="80px"
                type="number"
                debounceTime={500}
              />
            )
          },
          unit_selling_price: {
            value: item?.unit_selling_price,
            display: <div>{getValue(item?.unit_selling_price.toFixed(2))}</div>
          },
          total_selling_price_without_tax: {
            value: item?.total_selling_price_without_tax,
            display: <div>{getValue(item?.total_selling_price_without_tax.toFixed(2))}</div>
          },
          total_selling_price_with_tax: {
            value: item?.total_selling_price_with_tax,
            display: <div>{getValue(item?.total_selling_price_with_tax.toFixed(2))}</div>
          },
          margin_without_tax: {
            value: item?.margin_without_tax,
            display: <div>{getValue(item?.margin_without_tax.toFixed(2))}</div>
          },
          total_buying_price: {
            value: item?.total_buying_price,
            display: <div>{getValue(item?.total_buying_price.toFixed(2))}</div>
          },
          total_buying_price_with_change_rate: {
            value: item?.total_buying_price_with_change_rate,
            display: <div>{getValue(item?.total_buying_price_with_change_rate.toFixed(2))}</div>
          },
          delivery_delay: {
            value: item?.delivery_delay,
            display: (
              <CustomInput
                isDisabled={isOldQuotationVersion}
                defaultValue={item?.delivery_delay}
                placeholder="00"
                callBack={() => handleChange('delivery_delay', item)}
                width="80px"
                type="number"
              />
            )
          },
          manufacturing_days: {
            value: item?.manufacturing_days,
            display: (
              <CustomInput
                isDisabled={isOldQuotationVersion}
                defaultValue={getValue(item?.manufacturing_days?.toString())}
                placeholder="00"
                callBack={() => handleChange('manufacturing_days', item)}
                width="80px"
                type="number"
                debounceTime={500}
              />
            )
          },
          errorLineMessage: item?.reason ? (
            <div>
              We were not able to calculate the price of this product automatically. This is why you
              may see a very high price value. Reason is the following: {item.reason}. Contact us if
              you have any questions!
            </div>
          ) : null,
          extended: <ItemExtended quotationItem={item} onItemUpdate={handleItemUpdate} />
        };
      });
      setItemsPreProcessing(newItemsPreProcessing);
    }
  }, [items, referenceModalDisplayed, contactId]);

  const unSelectedItems = (id: number) => {
    setSelectedItems((prevSelected) => prevSelected.filter((obj) => obj !== id));
  };

  const handleLineSelected = (id: number, isUnSelected: boolean) => {
    if (isUnSelected) {
      unSelectedItems(id);
    } else {
      if (!selectedItems.includes(id)) {
        setSelectedItems((prevSelected) => [...prevSelected, id]);
      }
    }
  };

  const deleteItem = () => {
    selectedItems.map((ItemId) => {
      const deletedItem = items.find((item) => item.id === ItemId);
      del(
        delQuotationItem(
          deletedItem.company,
          contactId,
          deletedItem.quotation,
          deletedItem.quotation_version,
          deletedItem.id
        )
      );
      unSelectedItems(ItemId);
    });
    const newItems = items.filter((item) => !selectedItems.includes(item.id));
    setItems(newItems);
  };

  const handleClickCreateItemButton = async () => {
    const newItem = await post(
      postCreateQuotationItem(
        quotationDetails.company_id,
        contactId,
        quotationDetails.id,
        quotationVersion
      ),
      {}
    );

    const updatedItems = [...items];
    updatedItems.unshift(newItem.data);

    setItems(updatedItems);
  };

  const handleOptionClicked = async (isNewReference: boolean) => {
    const hash = location.hash.substring(1);
    const params = new URLSearchParams(hash);
    const contentId = params.get('content_id');
    const content_id = Number(contentId?.split('-')[0]);

    const newDuplicateItem = await post(
      postDuplicateQuotationItem(
        quotationDetails.company_id,
        contactId,
        quotationDetails.id,
        quotationVersion,
        content_id,
        isNewReference
      ),
      {}
    );
    const updatedItems = [...items];
    updatedItems.unshift(newDuplicateItem.data);

    setItems(updatedItems);
  };

  const handleDragAndDropItem = async (array: any[]) => {
    const dragArrayFormatted = {
      new_array: array
        .map((elem, index) => {
          return { [elem.id]: index + 1 };
        })
        .reduce((acc, obj) => {
          return { ...acc, ...obj };
        }, {})
    };

    setItemsPreProcessing(array);
    const newQuotationItemOrder = await patch(
      patchItemRankOrder(
        quotationDetails.company_id,
        contactId,
        quotationDetails.id,
        quotationVersion
      ),
      dragArrayFormatted
    );
    setItems(newQuotationItemOrder.data.quotation_items);
  };

  const updateItems = async (newQuotationItem: QuotationItem) => {
    const newQuotationItemPatched = await patch(
      patchQuotationItem(
        newQuotationItem.company,
        contactId,
        newQuotationItem.quotation,
        newQuotationItem.quotation_version,
        newQuotationItem.id
      ),
      { ...newQuotationItem }
    );

    const newItems = items.map((newItem) =>
      newItem.id === newQuotationItem.id
        ? {
            ...newQuotationItemPatched.data,
            item_rendered: {
              ...newQuotationItemPatched.data.item_rendered
            }
          }
        : newItem
    );
    setItems(newItems);
  };

  const quotationMoreOptionToolTip: JSX.Element[] = [
    <Button
      key="moreOption-tooltip-new-reference"
      variant={ButtonVariant.BLACK}
      text="Duplicate (same reference)"
      leftIcon={Copy()}
      sizing={ButtonSizing.EXTRA_SMALL}
      onClick={() => handleOptionClicked(true)}
    />,
    <Button
      key="moreOption-tooltip"
      variant={ButtonVariant.BLACK}
      text="Duplicate (new reference)"
      leftIcon={Copy()}
      sizing={ButtonSizing.EXTRA_SMALL}
      onClick={() => handleOptionClicked(false)}
    />
  ];

  useEffect(() => {
    setContactId(currentContact);
  }, [currentContact]);

  useEffect(() => {
    (async () => {
      if (
        quotationDetails &&
        contactId &&
        quotationDetails.company_id &&
        quotationDetails.contact_id &&
        quotationDetails.id
      ) {
        const version = quotationVersion
          ? quotationVersion
          : quotationDetails.quotation_versions.reduce((max, current) =>
              current.id > max.id ? current : max
            ).id;
        const itemsResult = await get(
          getQuotationItems(quotationDetails.company_id, contactId, quotationDetails.id, version)
        );
        const rfqResult = await get(
          getRequestForQuotation + `?company_id=${quotationDetails.company_id}`
        );
        setItems(itemsResult.data.results);
        setRfq(rfqResult.data);
        setIsItemsLoading(false);
      }
    })();
  }, [quotationVersion]);

  return (
    <>
      <Array
        template={quotationItems}
        content={itemsPreProcessing}
        extended={true}
        errorLineMessage={true}
        sorted={false}
        lineSelected={handleLineSelected}
        handleDragAndDrop={handleDragAndDropItem}
        isSearchable={false}
        isDragDisabled={isOldQuotationVersion}
        onLoading={!!isItemsLoading}
        moreOptions={quotationMoreOptionToolTip}
        tools={
          <ButtonContainer>
            {!isOldQuotationVersion && (
              <Button
                variant={ButtonVariant.SECONDARY}
                text="Create Item"
                leftIcon={WhiteCross()}
                sizing={ButtonSizing.EXTRA_SMALL}
                onClick={handleClickCreateItemButton}
                disabled={is_order_created}
              />
            )}
            {!isOldQuotationVersion && !!selectedItems.length && (
              <Button
                variant={ButtonVariant.ERROR}
                sizing={ButtonSizing.EXTRA_SMALL}
                text={`Delete (${selectedItems.length}) rows`}
                leftIcon={Trash()}
                onClick={deleteItem}
                disabled={is_order_created}
              />
            )}
          </ButtonContainer>
        }
      />
      {item && (
        <QuotationReferenceModal
          item={item}
          onClose={onCloseReferenceModal}
          isDisplayed={referenceModalDisplayed}
          callback={updateItems}
        />
      )}
    </>
  );
};

export default Items;

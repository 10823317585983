import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
`;

const Title = styled.div`
  margin-bottom: 8px;
`;

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    [{ size: ['small', 'normal', 'large', 'huge'] }],
    [
      {
        color: ['#000000', '#FFFFFF', '#FF0000', '#00FF00', '#0066FF', '#FFD700']
      }
    ],
    [
      {
        background: ['#000000', '#FFFFFF', '#FF0000', '#00FF00', '#0066FF', '#FFD700']
      }
    ],
    [{ list: 'ordered' }, { list: 'bullet' }]
  ]
};

const formats = [
  'bold',
  'italic',
  'underline',
  'strike',
  'size',
  'color',
  'background',
  'list',
  'ordered',
  'bullet'
];

interface CustomReactQuillProps {
  title?: string;
  placeholder?: string;
  text: string;
  isDisabled?: boolean;
  callBack?: (property?: string) => (value: string | number) => void;
  debounceTime?: number;
}

const CustomReactQuill: React.FC<CustomReactQuillProps> = ({
  title,
  placeholder,
  text,
  isDisabled,
  debounceTime = 0,
  callBack
}) => {
  const [value, setValue] = useState(text);

  useEffect(() => {
    setValue(value);
  }, [text]);

  const debouncedExec = useCallback(
    debounce((input: string) => {
      if (callBack) {
        const exec = callBack();
        exec(input);
      }
    }, debounceTime),
    [callBack]
  );

  const handleChange = (value: string) => {
    setValue(value);
    debouncedExec(value);
  };

  return (
    <Container>
      {title && <Title>{title}</Title>}
      <ReactQuill
        theme="snow"
        value={value}
        onChange={handleChange}
        modules={modules}
        formats={formats}
        placeholder={placeholder}
        readOnly={isDisabled}
      />
    </Container>
  );
};

export default CustomReactQuill;

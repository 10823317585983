// ----------- Companies  ----------- //
export const clientCompanies: Column[] = [
  { name: 'Company', property: 'company_name', size: 250 },
  { name: 'Status', property: 'status', size: 100 },
  { name: 'Account', property: 'account_type', size: 150 },
  { name: '', property: 'Options', size: 50 }
];

export const contactsCompanies: Column[] = [
  { name: 'Company', property: 'company_name', size: 200 },
  { name: 'Name', property: 'last_name', size: 150 },
  { name: 'Firstname', property: 'first_name', size: 150 },
  { name: 'Email', property: 'email_address', size: 250 },
  { name: 'Phone number', property: 'phone_number', size: 200 },
  { name: 'Salesman', property: 'sales_person', size: 100 },
  { name: '', property: 'Options', size: 50 }
];

export const addressCompanies: Column[] = [
  { name: 'Type', property: 'address_type', size: 100 },
  { name: 'Name', property: 'address_name', size: 150 },
  { name: 'Address', property: 'address_1', size: 250 },
  { name: 'Zip code', property: 'postal_code', size: 100 },
  { name: 'City', property: 'city', size: 100 },
  { name: 'State', property: 'state', size: 100 },
  { name: 'Country', property: 'country', size: 100 },
  { name: '', property: 'Options', size: 50 }
];

export const contactsCompany: Column[] = [
  { name: 'Name', property: 'last_name', size: 150 },
  { name: 'Firstname', property: 'first_name', size: 150 },
  { name: 'Job', property: 'job_name', size: 100 },
  { name: 'Phone number', property: 'phone_number', size: 200 },
  { name: 'Email', property: 'email_address', size: 200 },
  { name: 'Address', property: 'address_name', size: 150 },
  { name: 'Salesman', property: 'sales_person', size: 70 },
  { name: '', property: 'Options', size: 50 }
];

export const activitiesCompany: Column[] = [
  { name: 'Type', property: 'type', size: 150 },
  { name: 'Activities', property: 'activities', size: 150 },
  { name: 'Date', property: 'date', size: 100 },
  { name: 'Salesman', property: 'sales_person', size: 100 },
  { name: 'Admin', property: 'admin', size: 100 },
  { name: 'Status', property: 'status', size: 150 },
  { name: 'Price (euro)', property: 'price', size: 70 },
  { name: '', property: 'Options', size: 50 }
];

export const activitiesProvider: Column[] = [
  { name: 'Type', property: 'type', size: 150 },
  { name: 'Activities', property: 'activities', size: 150 },
  { name: 'Date', property: 'date', size: 100 },
  { name: 'Salesman', property: 'sales_person', size: 100 },
  { name: 'Admin', property: 'admin', size: 100 },
  { name: 'Status', property: 'status', size: 150 },
  { name: 'Price (euro)', property: 'price', size: 70 },
  { name: '', property: 'Options', size: 50 }
];

// ----------- Providers  ----------- //

export const clientProviders: Column[] = [
  { name: 'Provider', property: 'company_name', size: 250 },
  { name: 'Status', property: 'status', size: 100 },
  { name: 'Salesman', property: 'sales_person', size: 100 },
  { name: 'Account', property: 'account_type', size: 150 },
  { name: '', property: 'Options', size: 50 }
];

export const clientProvidersContact: Column[] = [
  { name: 'Company', property: 'company_name', size: 200 },
  { name: 'Name', property: 'last_name', size: 150 },
  { name: 'Firstname', property: 'first_name', size: 150 },
  { name: 'Email', property: 'email_address', size: 250 },
  { name: 'Phone number', property: 'phone_number', size: 200 },
  { name: 'Salesman', property: 'sales_person', size: 100 },
  { name: '', property: 'Options', size: 50 }
];

export const addressProviders: Column[] = [
  { name: 'Type', property: 'address_type', size: 100 },
  { name: 'Name', property: 'address_name', size: 150 },
  { name: 'Address', property: 'address_1', size: 250 },
  { name: 'Zip code', property: 'postal_code', size: 100 },
  { name: 'City', property: 'city', size: 100 },
  { name: 'State', property: 'state', size: 100 },
  { name: 'Country', property: 'country', size: 100 },
  { name: '', property: 'Options', size: 50 }
];

export const contactsProvider: Column[] = [
  { name: 'Name', property: 'last_name', size: 150 },
  { name: 'Firstname', property: 'first_name', size: 150 },
  { name: 'Job', property: 'job_name', size: 100 },
  { name: 'Phone number', property: 'phone_number', size: 200 },
  { name: 'Email', property: 'email_address', size: 200 },
  { name: 'Address', property: 'address_name', size: 150 },
  { name: 'Salesman', property: 'sales_person', size: 70 },
  { name: '', property: 'Options', size: 50 }
];

// ----------- Sales  ----------- //

export const salesQuotations: Column[] = [
  { name: 'Company', property: 'company_name', size: 150 },
  { name: 'Activities', property: 'activities', size: 150 },
  { name: 'Date', property: 'date', size: 100 },
  { name: 'Salesman', property: 'sales_person', size: 100 },
  { name: 'Admin', property: 'admin', size: 100 },
  { name: 'Status', property: 'status', size: 150 },
  { name: 'Price', property: 'price', size: 70 },
  { name: '', property: 'Options', size: 50 }
];

export const salesOrders: Column[] = [
  { name: '', property: 'Dropdown', size: 50 },
  { name: 'Company', property: 'company_name', size: 150 },
  { name: 'Order', property: 'reference', size: 150 },
  { name: 'Client order number', property: 'reference_id_from_customer', size: 150 },
  { name: 'Date', property: 'order_date', size: 100 },
  { name: 'Salesman', property: 'sales_person', size: 70 },
  { name: 'Admin', property: 'admin', size: 70 },
  { name: 'Status', property: 'status', size: 70 },
  { name: 'Price', property: 'price', size: 70 },
  { name: 'Margin', property: 'margin', size: 70 },
  { name: '', property: 'Options', size: 50 }
];

export const salesDeliveryNote: Column[] = [
  { name: '', property: 'Dropdown', size: 50 },
  { name: 'Delivery', property: 'reference', size: 150 },
  { name: 'Client', property: 'company_name', size: 150 },
  { name: 'Order', property: 'order_reference', size: 150 },
  { name: 'Tracking Number', property: 'tracking', size: 150 },
  { name: 'Date', property: 'note_date', size: 100 },
  { name: 'Salesman', property: 'sales_person', size: 70 },
  { name: 'Admin', property: 'admin', size: 70 },
  { name: 'Status', property: 'status', size: 70 },
  { name: 'Price', property: 'price', size: 70 },
  { name: '', property: 'Options', size: 50 }
];

export const providerDeliveryNotes: Column[] = [
  { name: '', property: 'Dropdown', size: 50 },
  { name: 'Delivery', property: 'reference', size: 150 },
  { name: 'Client', property: 'provider_name', size: 150 },
  { name: 'Purchase order', property: 'order_reference', size: 150 },
  { name: 'Tracking Number', property: 'tracking', size: 150 },
  { name: 'Date', property: 'note_date', size: 100 },
  { name: 'Salesman', property: 'sales_person', size: 70 },
  { name: 'Admin', property: 'admin', size: 70 },
  { name: 'Status', property: 'status', size: 70 },
  { name: 'Price', property: 'price', size: 70 },
  { name: '', property: 'Options', size: 50 }
];

export const quotationItems: Column[] = [
  { name: '', property: 'Dropdown', size: 50 },
  { name: 'Product type', property: 'product_name', size: 100 },
  { name: 'Reference', property: 'reference', size: 100 },
  { name: 'Internal Ref.', property: 'internal_reference', size: 200 },
  { name: 'Provider', property: 'provider', size: 100 },
  { name: 'RFQ', property: 'RFQ', size: 100 },
  { name: 'Manual Price', property: 'manual_price', size: 100 },
  { name: 'Qty', property: 'quantity', size: 70 },
  { name: 'Unit Cost', property: 'unit_cost', size: 70 },
  { name: 'Tooling', property: 'tooling_costs', size: 70 },
  { name: 'Other Costs', property: 'other_costs', size: 70 },
  { name: 'Total Buying Price', property: 'total_buying_price', size: 70 },
  { name: 'Total Buying Price (euros)', property: 'total_buying_price_with_change_rate', size: 70 },
  { name: 'Taxe Rate (VAT)', property: 'tax_rate', size: 70 },
  { name: 'Currency', property: 'provider_currency', size: 100 },
  { name: 'Change rate', property: 'change_rate', size: 70 },
  { name: 'Shipping', property: 'shipping_costs', size: 70 },
  { name: 'Margin', property: 'margin_rate', size: 70 },
  { name: 'Unit Selling Price', property: 'unit_selling_price', size: 100 },
  { name: 'Total Selling Price (HT)', property: 'total_selling_price_without_tax', size: 100 },
  { name: 'Total Selling Price (TTC)', property: 'total_selling_price_with_tax', size: 100 },
  { name: 'Margin (HT)', property: 'margin_without_tax', size: 100 },
  { name: 'Delivery Time', property: 'delivery_delay', size: 100 },
  { name: 'Lead Time', property: 'manufacturing_days', size: 100 },
  { name: '', property: 'Options', size: 50 }
];

export const orderItems: Column[] = [
  { name: '', property: 'Dropdown', size: 50 },
  { name: 'Product type', property: 'product_name', size: 100 },
  { name: 'Reference', property: 'reference', size: 100 },
  { name: 'Provider', property: 'provider', size: 100 },
  { name: 'PO', property: 'purchase_order', size: 100 },
  { name: 'RFQ', property: 'RFQ', size: 100 },
  { name: 'Qty', property: 'quantity', size: 70 },
  { name: 'Unit Cost', property: 'unit_cost', size: 70 },
  { name: 'Tooling', property: 'tooling_costs', size: 70 },
  { name: 'Other Costs', property: 'other_costs', size: 70 },
  { name: 'Total Buying Price', property: 'total_buying_price', size: 70 },
  { name: 'Total Buying Price (euros)', property: 'total_buying_price_with_change_rate', size: 70 },
  { name: 'Taxe Rate (VAT)', property: 'tax_rate', size: 70 },
  { name: 'Currency', property: 'provider_currency', size: 100 },
  { name: 'Change rate', property: 'change_rate', size: 70 },
  { name: 'Shipping', property: 'shipping_costs', size: 70 },
  { name: 'Margin', property: 'margin_rate', size: 70 },
  { name: 'Unit Selling Price', property: 'unit_selling_price', size: 100 },
  { name: 'Total Selling Price (HT)', property: 'total_selling_price_without_tax', size: 100 },
  { name: 'Total Selling Price (TTC)', property: 'total_selling_price_with_tax', size: 100 },
  { name: 'Margin (HT)', property: 'margin_without_tax', size: 100 },
  { name: 'Delivery Time', property: 'delivery_delay', size: 100 },
  { name: 'Lead Time', property: 'manufacturing_days', size: 100 },
  { name: '', property: 'Options', size: 50 }
];

export const purchaseItems: Column[] = [
  { name: '', property: 'Dropdown', size: 50 },
  { name: 'Product type', property: 'product_name', size: 100 },
  { name: 'Reference', property: 'reference', size: 100 },
  { name: 'Qty', property: 'quantity', size: 100 },
  { name: 'Unit Cost', property: 'unit_cost', size: 100 },
  { name: 'Tooling', property: 'tooling_costs', size: 100 },
  { name: 'Other Costs', property: 'other_costs', size: 100 },
  { name: 'Total Buying Price', property: 'total_buying_price', size: 100 },
  { name: 'Delivery Time', property: 'delivery_delay', size: 100 },
  { name: 'Lead Time', property: 'manufacturing_days', size: 100 }
];

export const quotationVersion: Column[] = [
  { name: 'Date', property: 'date', size: 100 },
  { name: 'Reference', property: 'reference', size: 300 },
  { name: 'Admin', property: 'admin', size: 100 }
];

export const SalesDeliveryNoteRecap: Column[] = [
  { name: 'Product type', property: 'product_name', size: 150 },
  { name: 'Reference', property: 'reference', size: 150 },
  { name: 'Order Quantity', property: 'total_delivered_quantity', size: 50 },
  { name: 'Remaining Quantity', property: 'total_remaining_quantity', size: 50 },
  { name: 'Delivery note Qty', property: 'order_quantity', size: 50 }
];

export const UpdateSalesDeliveryNoteRecap: Column[] = [
  { name: 'Reference', property: 'reference', size: 150 },
  { name: 'Order Quantity', property: 'total_delivered_quantity', size: 50 },
  { name: 'Remaining Quantity', property: 'total_remaining_quantity', size: 50 },
  { name: 'Delivery note Qty', property: 'order_quantity', size: 50 }
];

export const salesInvoices: Column[] = [
  { name: '', property: 'Dropdown', size: 50 },
  { name: 'Orders', property: 'order_reference', size: 150 },
  { name: 'Invoices', property: 'invoice_type', size: 70 },
  { name: 'Client', property: 'company_name', size: 70 },
  { name: 'Invoice number', property: 'reference', size: 100 },
  { name: 'Date', property: 'invoice_date', size: 70 },
  { name: 'Salesman', property: 'sales_person', size: 70 },
  { name: 'Admin', property: 'admin', size: 70 },
  { name: 'Total Price HT', property: 'total_price_without_tax', size: 70 },
  { name: 'Total Price TTC', property: 'total_price_with_tax', size: 70 },
  { name: 'Down Payment', property: 'down_payment', size: 70 },
  { name: 'Margin', property: 'margin', size: 70 },
  { name: 'Balance amount', property: 'invoice_balance_amount', size: 70 },
  { name: 'Status', property: 'status', size: 70 },
  { name: 'Related delivery note', property: 'invoice_reference', size: 150 },
  { name: '', property: 'Options', size: 50 }
];

export const salesClassicInvoices: Column[] = [
  { name: 'Client', property: 'company_name', size: 70 },
  { name: 'Order Reference', property: 'reference', size: 150 },
  { name: 'Salesman', property: 'sales_person', size: 70 },
  { name: 'Admin', property: 'admin', size: 70 },
  { name: 'Invoice number', property: 'order_date', size: 100 }
];

export const salesCreditNotes: Column[] = [
  { name: 'Client', property: 'company', size: 50 },
  { name: 'Reference', property: 'reference', size: 50 },
  { name: 'Credit note (visible by client)', property: 'note', size: 50 },
  { name: 'Date', property: 'credit_note_date', size: 70 },
  { name: 'Salesman', property: 'sales_person', size: 70 },
  { name: 'Admin', property: 'admin', size: 70 },
  { name: 'Status', property: 'status', size: 70 },
  { name: 'Credit Amt', property: 'amount', size: 70 },
  { name: 'Used Amt', property: 'amount_used', size: 70 }
];

export const providerCreditNotes: Column[] = [
  { name: 'Client', property: 'company', size: 50 },
  { name: 'Reference', property: 'reference', size: 50 },
  { name: 'Credit note (visible by client)', property: 'note', size: 50 },
  { name: 'Date', property: 'credit_note_date', size: 70 },
  { name: 'Salesman', property: 'sales_person', size: 70 },
  { name: 'Admin', property: 'admin', size: 70 },
  { name: 'Status', property: 'status', size: 70 },
  { name: 'Credit Amt', property: 'amount', size: 70 },
  { name: 'Used Amt', property: 'amount_used', size: 70 }
];

export const salesPayments: Column[] = [
  { name: 'Client', property: 'company', size: 50 },
  { name: 'Payment method', property: 'payment_method', size: 50 },
  { name: 'Bank', property: 'bank', size: 70 },
  { name: 'Amount', property: 'amount', size: 70 },
  { name: 'Date', property: 'created_at', size: 70 },
  { name: 'Related invoice', property: 'invoice_reference', size: 150 },
  { name: 'Credit Amt', property: 'credit_note_amount', size: 70 },
  { name: 'Internal note', property: 'internal_note', size: 70 }
];

export const salesPaymentsInvoices: Column[] = [
  { name: '', property: 'Dropdown', size: 50 },
  { name: 'Invoices pending', property: 'reference', size: 50 },
  { name: 'Date', property: 'date', size: 50 },
  { name: 'Price', property: 'price', size: 70 },
  { name: 'Down Payment', property: 'down_payment', size: 70 },
  { name: 'Amount Balance', property: 'balance', size: 70 },
  { name: 'Amount Receive', property: 'amount', size: 70 }
];

export const purchasesRequestForQuotation: Column[] = [
  { name: 'Providers', property: 'provider', size: 50 },
  { name: 'RFQ', property: 'reference', size: 100 },
  { name: 'Clients', property: 'company', size: 50 },
  { name: 'Purchases', property: 'provider_purchases', size: 100 },
  { name: 'Date', property: 'rfq_date', size: 100 },
  { name: 'Salesman', property: 'sales_person', size: 70 },
  { name: 'Admin', property: 'admin', size: 70 },
  { name: 'Status', property: 'status', size: 70 }
];

export const providersPurchases: Column[] = [
  { name: '', property: 'Dropdown', size: 50 },
  { name: 'Providers', property: 'provider', size: 100 },
  { name: 'Purchase order', property: 'reference', size: 100 },
  { name: 'Order', property: 'customer_order', size: 100 },
  { name: 'Date', property: 'created_at', size: 100 },
  { name: 'Admin', property: 'admin', size: 70 },
  { name: 'Total Buying Price ', property: 'total_price', size: 150 },
  { name: 'Currency', property: 'provider_currency', size: 100 },
  { name: 'Status', property: 'status', size: 70 },
  { name: 'Delivery', property: 'delivery_status', size: 150 }
];

export const providersInvoices: Column[] = [
  { name: '', property: 'Dropdown', size: 50 },
  { name: 'Invoices', property: 'invoice_type', size: 70 },
  { name: 'Provider', property: 'company_name', size: 70 },
  { name: 'Invoice number', property: 'reference', size: 100 },
  { name: 'Date', property: 'invoice_date', size: 70 },
  { name: 'Salesman', property: 'sales_person', size: 70 },
  { name: 'Admin', property: 'admin', size: 70 },
  { name: 'Total Price (euros)', property: 'total_price_without_tax', size: 70 },
  { name: 'Total Buying Price', property: 'total_price_with_tax', size: 70 },
  { name: 'Down Payment', property: 'down_payment', size: 70 },
  { name: 'Margin', property: 'margin', size: 70 },
  { name: 'Balance amount', property: 'invoice_balance_amount', size: 70 },
  { name: 'Status', property: 'status', size: 70 },
  { name: 'Related delivery note', property: 'invoice_reference', size: 150 },
  { name: '', property: 'Options', size: 50 }
];

export const providersPayments: Column[] = [
  { name: 'Client', property: 'company', size: 50 },
  { name: 'Payment method', property: 'payment_method', size: 50 },
  { name: 'Bank', property: 'bank', size: 70 },
  { name: 'Amount', property: 'amount', size: 70 },
  { name: 'Date', property: 'created_at', size: 70 },
  { name: 'Related invoice', property: 'invoice_reference', size: 150 },
  { name: 'Credit Amt', property: 'credit_note_amount', size: 70 },
  { name: 'Internal note', property: 'internal_note', size: 70 }
];

// Global

export const relatedDocuments: Column[] = [
  { name: 'Type', property: 'type', size: 150 },
  { name: 'Date', property: 'date', size: 100 },
  { name: 'Documents', property: 'reference', size: 300 },
  { name: 'Creator', property: 'admin', size: 100 }
];

import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { debounce } from 'lodash';

import useApi from 'services/axios';
import {
  delQuotationItem,
  getQuotationItems,
  getRequestForQuotation,
  patchItemRankOrder,
  patchQuotationItem
} from 'services/endpoints';
import { AllProvidersContext, CurrencyContext } from 'contexts';
import { Button, CustomInput, CustomLink, CustomSelect } from 'components/common';
import { getSelectStyles } from 'utils/style';
import { CreatePurchaseModal, QuotationReferenceModal } from 'components/modal';
import { getValue, useProvidersState } from 'components/Tools';
import { ButtonSizing, ButtonVariant } from 'components/common/Button';
import { Trash, WhiteCross } from 'svgs';

import { Array, orderItems } from '../array';

import ItemExtended from './ItemExtended';

const ButtonContainer = styled.div`
  position: sticky;
  left: 0;
  display: flex;
  gap: 8px;
`;

const CustomProvidersSelectCurrency: React.FC<{
  quotationItem: OrderItem;
  quotationItems: OrderItem[];
  orderDetails: Partial<Order>;
  setItems: React.Dispatch<React.SetStateAction<OrderItem[]>>;
  isDisabled: boolean;
}> = ({ quotationItem, quotationItems, setItems, isDisabled }) => {
  const { state: currencyState } = useContext(CurrencyContext.Context);
  const { patch } = useApi();

  const currencyOption = currencyState.results.map((curr: Currency) => {
    return {
      label: curr.name,
      value: curr.id
    };
  });

  const [currency, setCurrency] = useState<Option>(
    currencyOption.find((curr) => curr.value === quotationItem.provider_currency)
  );

  const handleChange = async (e: Option) => {
    setCurrency(e);

    const newQuotationItem = await patch(
      patchQuotationItem(
        quotationItem.company,
        quotationItem.contact,
        quotationItem.quotation,
        quotationItem.quotation_version,
        quotationItem.id
      ),
      {
        ...quotationItem,
        provider_currency: e.value
      }
    );

    const newItems = quotationItems.map((quotationItems) =>
      quotationItems.id === quotationItem.id ? newQuotationItem.data : quotationItems
    );

    setItems(newItems);
  };

  return (
    <CustomSelect
      placeholder="Currency"
      value={currency}
      options={currencyOption}
      onChange={(e: Option) => {
        handleChange(e);
      }}
      isMulti={false}
      isSearchable={true}
      isDisabled={isDisabled}
      styles={getSelectStyles(null, '150px', '50px')}
      usePortalStyles={true}
    />
  );
};

const CustomProvidersSelectProcessing: React.FC<{
  quotationItem: OrderItem;
  quotationItems: OrderItem[];
  setItems: React.Dispatch<React.SetStateAction<OrderItem[]>>;
}> = ({ quotationItem, quotationItems, setItems }) => {
  const { patch } = useApi();
  const { state: allProvidersState } = useContext(AllProvidersContext.Context);

  const providerPreProcessing = allProvidersState.map((provider) => {
    return {
      label: provider.company_name,
      value: provider.id
    };
  });

  const [provider, setProviders] = useState<Option>(
    providerPreProcessing.find((product) => product.value === quotationItem.provider)
  );

  const handleChange = async (e: Option) => {
    setProviders(e);

    const newOrderItem = await patch(
      patchQuotationItem(
        quotationItem.company,
        quotationItem.contact,
        quotationItem.quotation,
        quotationItem.quotation_version,
        quotationItem.id
      ),
      {
        ...quotationItem,
        provider: e.value
      }
    );

    const newItems = quotationItems.map((quotationItems) =>
      quotationItems.id === quotationItem.id ? newOrderItem.data : quotationItems
    );

    setItems(newItems);
  };

  return (
    <CustomSelect
      placeholder="Provider"
      value={provider}
      options={providerPreProcessing}
      onChange={(e: Option) => {
        handleChange(e);
      }}
      isMulti={false}
      isSearchable={true}
      isDisabled={true}
      styles={getSelectStyles(null, '150px', '50px')}
      usePortalStyles={true}
    />
  );
};

type OrderItemsProps = {
  orderDetails: Partial<Order>;
  unlockItems: number;
};

const OrderItems: React.FC<OrderItemsProps> = ({ orderDetails, unlockItems }) => {
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [itemsPreProcessing, setItemsPreProcessing] = useState([]);
  const [items, setItems] = useState<OrderItem[]>([]);
  const [item, setItem] = useState<OrderItem>();
  const [isItemsLoading, setIsItemsLoading] = useState<boolean>(true);
  const [, setDebounceInput] = useState<DebounceInputState>([]);
  const [rfq, setRfq] = useState<any>();
  const [referenceModalDisplayed, setReferenceModalDisplayed] = useState(false);
  const { findProviderById } = useProvidersState();
  const { get, patch, del } = useApi();
  const [purchaseButtonDisplayed, setPurchaseButtonDisplayed] = useState<boolean>(true);
  const [purchaseModalDisplayed, setPurchaseModalDisplayed] = useState(false);

  const getProviderName = (ItemProviderId: number) =>
    findProviderById(ItemProviderId)?.company_name;

  const getRfqName = (ItemRfqId: number) =>
    rfq?.results.find((rfqItem: any) => rfqItem.id === ItemRfqId)?.reference;

  const handleReferenceClick = (item: OrderItem) => {
    setItem(item);
    setReferenceModalDisplayed(true);
  };

  const unSelectedItems = (id: number) => {
    setSelectedItems((prevSelected) => prevSelected.filter((obj) => obj !== id));
  };

  const handleLineSelected = (id: number, isUnSelected: boolean) => {
    if (isUnSelected) {
      unSelectedItems(id);
    } else {
      if (!selectedItems.includes(id)) {
        setSelectedItems((prevSelected) => [...prevSelected, id]);
      }
    }
  };

  const checkSameCompany = (items: OrderItem[]): boolean => {
    if (items.length === 0) return true;

    const company = items[0].provider;

    return items.every((item) => item.provider === company);
  };

  const handleClickCreatePurchaseButton = () => setPurchaseModalDisplayed(true);

  const onClosePurchaseModal = () => setPurchaseModalDisplayed(false);

  const onCloseReferenceModal = () => setReferenceModalDisplayed(false);

  const debouncedPatchesRef = useRef<{ [key: number]: ReturnType<typeof debounce> }>({});

  const getDebouncedPatch = (id: number) => {
    if (!debouncedPatchesRef.current[id]) {
      debouncedPatchesRef.current[id] = debounce(
        async (
          itemDetails: OrderItem,
          itemName: string,
          itemValue: string | number,
          currentDebounceInput: Partial<OrderItem>
        ) => {
          try {
            const newQuotationItem = await patch(
              patchQuotationItem(
                itemDetails.company,
                itemDetails.contact,
                itemDetails.quotation,
                itemDetails.quotation_version,
                itemDetails.id
              ),
              {
                ...itemDetails,
                ...currentDebounceInput,
                [itemName]: itemValue
              }
            );
            setItems((prevItems) =>
              prevItems.map((item) => (item.id === itemDetails.id ? newQuotationItem.data : item))
            );
          } catch (error) {
            console.error('Could not update item', error);
          }
        },
        300
      );
    }
    return debouncedPatchesRef.current[id];
  };

  const handleChange = (name: string, details: OrderItem) => (value: string | number) => {
    setDebounceInput((prevState) => {
      const newState = {
        ...prevState,
        [details?.id]: {
          ...prevState[details?.id],
          [name]: value
        }
      };
      const debouncedPatch = getDebouncedPatch(details.id);
      debouncedPatch(details, name, value, newState[details.id]);
      return newState;
    });
  };

  const handleDragAndDropItem = async (array: any[]) => {
    const dragArrayFormatted = {
      new_array: array
        .map((elem, index) => {
          return { [elem.id]: index + 1 };
        })
        .reduce((acc, obj) => {
          return { ...acc, ...obj };
        }, {})
    };

    setItemsPreProcessing(array);

    const newQuotationItemOrder = await patch(
      patchItemRankOrder(
        orderDetails.company,
        orderDetails.contact,
        orderDetails.quotation_id,
        orderDetails.quotation_version_id
      ),
      dragArrayFormatted
    );

    setItems(newQuotationItemOrder.data.quotation_items);
  };

  const deleteItem = () => {
    selectedItems.map((ItemId) => {
      const deletedItem = items.find((item) => item.id === ItemId);
      del(
        delQuotationItem(
          deletedItem.company,
          deletedItem.contact,
          deletedItem.quotation,
          deletedItem.quotation_version,
          deletedItem.id
        )
      );
      unSelectedItems(ItemId);
    });
    const newItems = items.filter((item) => !selectedItems.includes(item.id));
    setItems(newItems);
  };

  const handleItemUpdate = (
    updater: (
      prevItem: QuotationItem | OrderItem | ProviderPurchaseItem
    ) => QuotationItem | OrderItem | ProviderPurchaseItem
  ) => {
    setItems((prevItems) =>
      prevItems.map((item) => {
        const updatedItem = updater(item);
        return item.id === updatedItem.id ? (updatedItem as OrderItem) : item;
      })
    );
  };

  useEffect(() => {
    const newItemsPreProcessing = items.map((item) => {
      return {
        id: item?.id,
        product_name: {
          value: item?.item_rendered?.product_name,
          display: (
            <div>{item?.item_rendered?.product_name ? item?.item_rendered?.product_name : '-'}</div>
          )
        },
        reference: {
          value: item?.item_rendered?.product_information?.reference,
          display: (
            <CustomInput
              isDisabled={!orderDetails.is_last_quotation_version_editable}
              defaultValue={item?.item_rendered?.product_information?.reference}
              placeholder="reference"
              onClick={() => handleReferenceClick(item)}
            />
          )
        },
        internal_reference: {
          value: item?.item_rendered?.internal_reference,
          display: (
            <div>
              {item?.item_rendered?.internal_reference
                ? item?.item_rendered?.internal_reference
                : '-'}
            </div>
          )
        },
        provider: {
          value: getProviderName(item?.provider),
          display: item?.provider ? (
            <div>{getValue(getProviderName(item?.provider))}</div>
          ) : (
            <CustomProvidersSelectProcessing
              quotationItem={item}
              quotationItems={items}
              setItems={setItems}
            />
          )
        },
        purchase_order: {
          value: item?.purchase_order?.ref,
          display: item?.purchase_order?.ref ? (
            <CustomLink
              link={`/Purchases-Purchases/Purchase?PurchaseId=${item?.purchase_order?.id}`}
              text={item?.purchase_order?.ref}
            />
          ) : (
            <div>-</div>
          )
        },
        RFQ: {
          value: getRfqName(item?.rfq),
          display: <div>{getValue(getRfqName(item?.rfq))}</div>
        },
        quantity: {
          value: item?.quantity,
          display: (
            <CustomInput
              isDisabled={!orderDetails.is_last_quotation_version_editable}
              defaultValue={item?.quantity.toString()}
              placeholder="00"
              callBack={() => handleChange('quantity', item)}
              width="70px"
            />
          )
        },
        unit_cost: {
          value: item?.unit_cost,
          display: (
            <CustomInput
              isDisabled={!orderDetails.is_last_quotation_version_editable}
              defaultValue={item?.unit_cost.toString()}
              placeholder="00"
              callBack={() => handleChange('unit_cost', item)}
              width="70px"
            />
          )
        },
        tooling_costs: {
          value: item?.tooling_costs,
          display: (
            <CustomInput
              isDisabled={!orderDetails.is_last_quotation_version_editable}
              defaultValue={item?.tooling_costs.toString()}
              placeholder="00"
              callBack={() => handleChange('tooling_costs', item)}
              width="70px"
            />
          )
        },
        other_costs: {
          value: item?.other_costs,
          display: (
            <CustomInput
              isDisabled={!orderDetails.is_last_quotation_version_editable}
              defaultValue={item?.other_costs.toString()}
              placeholder="00"
              callBack={() => handleChange('other_costs', item)}
              width="70px"
            />
          )
        },
        tax_rate: {
          value: item?.tax_rate,
          display: (
            <CustomInput
              isDisabled={!orderDetails.is_last_quotation_version_editable}
              defaultValue={item?.tax_rate.toString()}
              placeholder="00"
              callBack={() => handleChange('tax_rate', item)}
              width="70px"
            />
          )
        },
        provider_currency: {
          value: item?.change_rate,
          display: (
            <CustomProvidersSelectCurrency
              quotationItem={item}
              quotationItems={items}
              orderDetails={orderDetails}
              setItems={setItems}
              isDisabled={!orderDetails.is_last_quotation_version_editable}
            />
          )
        },
        change_rate: {
          value: item?.change_rate,
          display: <div>{getValue(item?.change_rate?.toString())}</div>
        },
        shipping_costs: {
          value: item?.shipping_costs,
          display: (
            <CustomInput
              isDisabled={!orderDetails.is_last_quotation_version_editable}
              defaultValue={item?.shipping_costs.toString()}
              placeholder="00"
              callBack={() => handleChange('shipping_costs', item)}
              width="70px"
            />
          )
        },
        margin_rate: {
          value: item?.margin_rate,
          display: (
            <CustomInput
              isDisabled={!orderDetails.is_last_quotation_version_editable}
              defaultValue={item?.margin_rate.toString()}
              placeholder="00"
              callBack={() => handleChange('margin_rate', item)}
              width="70px"
            />
          )
        },
        unit_selling_price: {
          value: item?.unit_selling_price,
          display: <div>{getValue(item?.unit_selling_price.toFixed(2).toString())}</div>
        },
        total_selling_price_without_tax: {
          value: item?.total_selling_price_without_tax,
          display: (
            <div>{getValue(item?.total_selling_price_without_tax.toFixed(2).toString())}</div>
          )
        },
        total_selling_price_with_tax: {
          value: item?.total_selling_price_with_tax,
          display: <div>{getValue(item?.total_selling_price_with_tax.toFixed(2).toString())}</div>
        },
        margin_without_tax: {
          value: item?.margin_without_tax,
          display: <div>{getValue(item?.margin_without_tax.toFixed(2).toString())}</div>
        },
        total_buying_price: {
          value: item?.total_buying_price,
          display: <div>{getValue(item?.total_buying_price.toFixed(2).toString())}</div>
        },
        total_buying_price_with_change_rate: {
          value: item?.total_buying_price_with_change_rate,
          display: (
            <div>{getValue(item?.total_buying_price_with_change_rate.toFixed(2).toString())}</div>
          )
        },
        delivery_delay: {
          value: item?.delivery_delay,
          display: (
            <CustomInput
              isDisabled={!orderDetails.is_last_quotation_version_editable}
              defaultValue={item?.delivery_delay.toString()}
              placeholder="00"
              callBack={() => handleChange('delivery_delay', item)}
              width="70px"
            />
          )
        },
        manufacturing_days: {
          value: item?.manufacturing_days,
          display: (
            <CustomInput
              isDisabled={!orderDetails.is_last_quotation_version_editable}
              defaultValue={getValue(item?.manufacturing_days.toString())}
              placeholder="00"
              callBack={() => handleChange('manufacturing_days', item)}
              width="70px"
            />
          )
        },
        extended: <ItemExtended quotationItem={item} onItemUpdate={handleItemUpdate} />
      };
    });
    setItemsPreProcessing(newItemsPreProcessing);
  }, [items]);

  useEffect(() => {
    if (selectedItems.length) {
      const itemsFiltered = orderDetails.order_items.filter((filterItem) =>
        selectedItems.includes(filterItem.id)
      );
      setPurchaseButtonDisplayed(checkSameCompany(itemsFiltered));
    }
  }, [selectedItems]);

  useEffect(() => {
    (async () => {
      const rfqResult = await get(getRequestForQuotation + `?company_id=${orderDetails.company}`);
      const itemsResult = await get(
        getQuotationItems(
          orderDetails.company,
          orderDetails.contact,
          orderDetails.quotation_id,
          orderDetails.quotation_version_id
        )
      );
      setItems(itemsResult.data.results);
      setRfq(rfqResult.data);
      setIsItemsLoading(false);
    })();
  }, [unlockItems]);

  return (
    <>
      {orderDetails && (
        <>
          <Array
            template={orderItems}
            content={itemsPreProcessing}
            extended={true}
            sorted={false}
            lineSelected={handleLineSelected}
            isSearchable={false}
            isDragDisabled={!orderDetails.is_last_quotation_version_editable}
            handleDragAndDrop={handleDragAndDropItem}
            onLoading={!!isItemsLoading}
            tools={
              <ButtonContainer>
                {!!selectedItems.length && (
                  <Button
                    variant={ButtonVariant.SECONDARY}
                    sizing={ButtonSizing.EXTRA_SMALL}
                    text={`Create purchases`}
                    leftIcon={WhiteCross()}
                    onClick={handleClickCreatePurchaseButton}
                    disabled={!purchaseButtonDisplayed}
                  />
                )}
                {orderDetails.is_last_quotation_version_editable && !!selectedItems.length && (
                  <Button
                    variant={ButtonVariant.ERROR}
                    sizing={ButtonSizing.EXTRA_SMALL}
                    text={`Delete (${selectedItems.length}) rows`}
                    leftIcon={Trash()}
                    onClick={deleteItem}
                  />
                )}
              </ButtonContainer>
            }
          />
          {item && (
            <QuotationReferenceModal
              item={item}
              onClose={onCloseReferenceModal}
              isDisplayed={referenceModalDisplayed}
            />
          )}
          <CreatePurchaseModal
            onClose={onClosePurchaseModal}
            isDisplayed={purchaseModalDisplayed}
            orderItems={orderDetails.order_items}
            orderItemsSelected={selectedItems}
          ></CreatePurchaseModal>
        </>
      )}
    </>
  );
};

export default OrderItems;

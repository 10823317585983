import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';

import { getValue } from 'components/Tools';
import { Button, ButtonSizing, ButtonVariant, SmallLoader } from 'components/common';
import { delDocument, getDocument, getGerFile, postDocument } from 'services/endpoints';
import useApi from 'services/axios';
import { Trash, Download } from 'svgs';

const DocumentListContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  gap: 8px;
  border-bottom: 1px solid #e0e0e0;
`;

const DocumentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const DocumentsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 400px);
  gap: 32px;
`;

const DocumentName = styled.span`
  word-break: break-all;
  flex-grow: 1;
  font-size: 14px;
  color: #333;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;
`;

const ExtendedContainer = styled.div`
  display: flex;
  gap: 32px;
`;

const AdditionalCommentContainer = styled.div`
  width: 200px;
`;

const ExtendedTitle = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #434d56;
`;

const List = styled.ul`
  margin: 0;
`;

const Section = styled.div`
  display: flex;
  margin-top: 16px;
`;

const SectionColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
`;

const PCBNoProduct = () => (
  <ExtendedContainer>
    <div>
      Please select a <b>Product Type</b> and define <b>Product Reference</b> to see a small recap
      here
    </div>
  </ExtendedContainer>
);

const PCBAdditionalComment: React.FC<ItemExtendedProps> = ({ quotationItem, onItemUpdate }) => (
  <ExtendedContainer>
    <div>
      <ExtendedTitle>Additionnal comment</ExtendedTitle>
      <Section>
        {getValue(quotationItem?.item_rendered?.product_information?.additional_comment)}
      </Section>
    </div>
    <DocumentsItem quotationItem={quotationItem} onItemUpdate={onItemUpdate} />
  </ExtendedContainer>
);

const PCBCao: React.FC<ItemExtendedProps> = ({ quotationItem, onItemUpdate }) => (
  <ExtendedContainer>
    <div>
      <ExtendedTitle>Product parameters</ExtendedTitle>
      <Section>
        <List>
          <li>
            Source files provided:{' '}
            {getValue(quotationItem?.item_rendered?.product_information?.source_files_provided)}
          </li>
          <li>
            output files:{' '}
            {getValue(quotationItem?.item_rendered?.product_information?.output_files)}
          </li>
        </List>
      </Section>
    </div>
    <div>
      <ExtendedTitle>Additionnal comment</ExtendedTitle>
      <Section>
        <div>{getValue(quotationItem?.item_rendered?.product_information?.additional_comment)}</div>
      </Section>
    </div>
    <DocumentsItem quotationItem={quotationItem} onItemUpdate={onItemUpdate} />
  </ExtendedContainer>
);

const PCBStencil: React.FC<ItemExtendedProps> = ({ quotationItem, onItemUpdate }) => (
  <ExtendedContainer>
    <div>
      <ExtendedTitle>Product parameters</ExtendedTitle>
      <Section>
        <List>
          <li>
            thickness: {getValue(quotationItem?.item_rendered?.product_information?.thickness)}
          </li>
        </List>
      </Section>
    </div>
    <div>
      <ExtendedTitle>Additionnal comment</ExtendedTitle>
      <Section>
        {getValue(quotationItem?.item_rendered?.product_information?.additional_comment)}
      </Section>
    </div>
    <DocumentsItem quotationItem={quotationItem} onItemUpdate={onItemUpdate} />
  </ExtendedContainer>
);

const PCBStudy: React.FC<ItemExtendedProps> = ({ quotationItem, onItemUpdate }) => (
  <ExtendedContainer>
    <div>
      <ExtendedTitle>Product parameters</ExtendedTitle>
      <Section>
        <List>
          <li>
            Special specifications:{' '}
            {getValue(quotationItem?.item_rendered?.product_information?.special_specifications)}
          </li>
          <li>
            Study and development (costing_includes):{' '}
            {getValue(
              quotationItem?.item_rendered?.product_information
                ?.study_and_development_costing_includes
            )}
          </li>
          <li>
            Costing Remarks:{' '}
            {getValue(quotationItem?.item_rendered?.product_information?.costing_remarks)}
          </li>
        </List>
      </Section>
    </div>
    <div>
      <ExtendedTitle>Additionnal comment</ExtendedTitle>
      <Section>
        {getValue(quotationItem?.item_rendered?.product_information?.additional_comment)}
      </Section>
    </div>
    <DocumentsItem quotationItem={quotationItem} onItemUpdate={onItemUpdate} />
  </ExtendedContainer>
);

const PCBExtended: React.FC<ItemExtendedProps> = ({ quotationItem, onItemUpdate }) => {
  const { get } = useApi();

  const getGerberFileDocument = async (file: GerberFile) => {
    const response = await get(
      getGerFile(
        quotationItem.company,
        quotationItem.contact,
        quotationItem.quotation,
        quotationItem.quotation_version,
        quotationItem.id,
        file.id
      ),
      'blob'
    );

    const blob = new Blob([response.data], { type: 'application/zip' });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', getFileName(file));
    document.body.appendChild(link);

    link.click();
    window.URL.revokeObjectURL(url);
  };
  const getFileName = (file: GerberFile) => {
    return file.file.split('/').reverse()[0];
  };
  return (
    <ExtendedContainer>
      <div>
        <ExtendedTitle>Product parameters</ExtendedTitle>
        <Section>
          <List>
            <li>UOM: {getValue(quotationItem?.item_rendered?.product_information?.uom)}</li>
            <li>
              Delivery Format:{' '}
              {getValue(quotationItem?.item_rendered?.product_information?.delivery_format)}
            </li>
            <li>
              Cut Layers: {getValue(quotationItem?.item_rendered?.product_information?.cut_layers)}
            </li>
            <li>
              PCB Thickness:{' '}
              {getValue(quotationItem?.item_rendered?.product_information?.pcb_thickness)}
            </li>
            <li>
              PCB Size X:{' '}
              {getValue(quotationItem?.item_rendered?.product_information?.pcb_size_x.toString())}
            </li>
            <li>
              PCB Size Y:{' '}
              {getValue(quotationItem?.item_rendered?.product_information?.pcb_size_y.toString())}
            </li>
            <li>
              PCB Area:{' '}
              {getValue(quotationItem?.item_rendered?.product_information?.pcb_area.toString())}
            </li>
            <li>
              PCB Quantity X:{' '}
              {getValue(
                quotationItem?.item_rendered?.product_information?.pcb_quantity_x.toString()
              )}
            </li>
            <li>
              PCB Quantity Y:{' '}
              {getValue(
                quotationItem?.item_rendered?.product_information?.pcb_quantity_y.toString()
              )}
            </li>
            <li>
              PCB Separation X:{' '}
              {getValue(quotationItem?.item_rendered?.product_information?.pcb_separation_x)}
            </li>
            <li>
              PCB Separation Y:{' '}
              {getValue(quotationItem?.item_rendered?.product_information?.pcb_separation_y)}
            </li>
            <li>
              Space Between PCB X:{' '}
              {getValue(
                quotationItem?.item_rendered?.product_information?.space_between_pcb_x?.toString()
              )}
            </li>
            <li>
              Space Between PCB Y:{' '}
              {getValue(
                quotationItem?.item_rendered?.product_information?.space_between_pcb_y?.toString()
              )}
            </li>
            <li>
              PCB Separation Length X:{' '}
              {getValue(
                quotationItem?.item_rendered?.product_information?.pcb_separation_length_x?.toString()
              )}
            </li>
            <li>
              PCB Separation Length Y:{' '}
              {getValue(
                quotationItem?.item_rendered?.product_information?.pcb_separation_length_y?.toString()
              )}
            </li>
            <li>
              Space Border Left:{' '}
              {getValue(
                quotationItem?.item_rendered?.product_information?.space_border_left?.toString()
              )}
            </li>
            <li>
              Space Border Right:{' '}
              {getValue(
                quotationItem?.item_rendered?.product_information?.space_border_right?.toString()
              )}
            </li>
            <li>
              Space Border Top:{' '}
              {getValue(
                quotationItem?.item_rendered?.product_information?.space_border_top?.toString()
              )}
            </li>
            <li>
              Space Border Bottom:{' '}
              {getValue(
                quotationItem?.item_rendered?.product_information?.space_border_bottom?.toString()
              )}
            </li>
            <li>
              Array Size X:{' '}
              {getValue(quotationItem?.item_rendered?.product_information?.array_size_x.toString())}
            </li>
            <li>
              Array Size Y:{' '}
              {getValue(quotationItem?.item_rendered?.product_information?.array_size_y.toString())}
            </li>
            <li>
              Array Area:{' '}
              {getValue(quotationItem?.item_rendered?.product_information?.array_area.toString())}
            </li>
            <li>X Outs: {getValue(quotationItem?.item_rendered?.product_information?.x_outs)}</li>
            <li>
              Multiple Design:{' '}
              {getValue(quotationItem?.item_rendered?.product_information?.multiple_design)}
            </li>
            <li>
              Cut Layers: {getValue(quotationItem?.item_rendered?.product_information?.cut_layers)}
            </li>
            <li>
              PCB Thickness:{' '}
              {getValue(quotationItem?.item_rendered?.product_information?.pcb_thickness)}
            </li>
            <li>
              Base Material:{' '}
              {getValue(quotationItem?.item_rendered?.product_information?.base_material)}
            </li>
            <li>
              Final Cu Outer:{' '}
              {getValue(quotationItem?.item_rendered?.product_information?.final_cu_outer)}
            </li>
            <li>
              Final Cu Inner:{' '}
              {getValue(quotationItem?.item_rendered?.product_information?.final_cu_inner)}
            </li>
            <li>
              Surface Finish:{' '}
              {getValue(quotationItem?.item_rendered?.product_information?.surface_finish)}
            </li>
            <li>
              Solder Mask:{' '}
              {getValue(quotationItem?.item_rendered?.product_information?.solder_mask)}
            </li>
            <li>Legend: {getValue(quotationItem?.item_rendered?.product_information?.legend)}</li>
            <li>
              Mask Color: {getValue(quotationItem?.item_rendered?.product_information?.mask_color)}
            </li>
            <li>
              Legend Color:{' '}
              {getValue(quotationItem?.item_rendered?.product_information?.legend_color)}
            </li>
            <li>RoHS: {getValue(quotationItem?.item_rendered?.product_information?.rohs)}</li>
            <li>
              Minimum Space:{' '}
              {getValue(quotationItem?.item_rendered?.product_information?.min_space)}
            </li>
            <li>
              Minimum Drill Size:{' '}
              {getValue(quotationItem?.item_rendered?.product_information?.min_drill_size)}
            </li>
            <li>Stackup: {getValue(quotationItem?.item_rendered?.product_information?.stackup)}</li>
            <li>
              Minimum Line: {getValue(quotationItem?.item_rendered?.product_information?.min_line)}
            </li>
            <li>
              E-Test Flying Probe:{' '}
              {getValue(quotationItem?.item_rendered?.product_information?.etest_flying_probe)}
            </li>
            <li>
              IPC Class: {getValue(quotationItem?.item_rendered?.product_information?.ipc_class)}
            </li>
            <li>
              Press Fit: {getValue(quotationItem?.item_rendered?.product_information?.press_fit)}
            </li>
            <li>
              E-Test Fixture:{' '}
              {getValue(quotationItem?.item_rendered?.product_information?.etest_fixture)}
            </li>
          </List>
          <List>
            <li>
              Print Serial Number:{' '}
              {getValue(quotationItem?.item_rendered?.product_information?.print_serial_number)}
            </li>
            <li>
              E-Test Points Panel:{' '}
              {getValue(quotationItem?.item_rendered?.product_information?.etest_points_panel)}
            </li>
            <li>
              LED Order: {getValue(quotationItem?.item_rendered?.product_information?.led_order)}
            </li>
            <li>
              Mask Type: {getValue(quotationItem?.item_rendered?.product_information?.mask_type)}
            </li>
            <li>
              Masking Two X:{' '}
              {getValue(quotationItem?.item_rendered?.product_information?.masking_two_x)}
            </li>
            <li>
              Via Resign Plug:{' '}
              {getValue(quotationItem?.item_rendered?.product_information?.via_resign_plug)}
            </li>
            <li>
              Via Masking:{' '}
              {getValue(quotationItem?.item_rendered?.product_information?.via_masking)}
            </li>
            <li>
              Control Dielectric:{' '}
              {getValue(quotationItem?.item_rendered?.product_information?.control_dielectric)}
            </li>
            <li>
              Impedance Control:{' '}
              {getValue(quotationItem?.item_rendered?.product_information?.imped_ctrl)}
            </li>
            <li>Coupons: {getValue(quotationItem?.item_rendered?.product_information?.coupons)}</li>
            <li>
              Non-Standard Stackup Core:{' '}
              {getValue(quotationItem?.item_rendered?.product_information?.non_std_stackup_core)}
            </li>
            <li>
              Route Length Panel:{' '}
              {getValue(
                quotationItem?.item_rendered?.product_information?.route_len_panel.toString()
              )}
            </li>
            <li>
              Abnormal Hole:{' '}
              {getValue(quotationItem?.item_rendered?.product_information?.abnormal_hole)}
            </li>
            <li>
              Gold Finger Thickness:{' '}
              {getValue(quotationItem?.item_rendered?.product_information?.gold_finger_thickness)}
            </li>
            <li>
              Non-Standard Stackup PP:{' '}
              {getValue(quotationItem?.item_rendered?.product_information?.non_std_stackup_pp)}
            </li>
            <li>
              Z Routing: {getValue(quotationItem?.item_rendered?.product_information?.z_routing)}
            </li>
            <li>
              Counter Sunk:{' '}
              {getValue(quotationItem?.item_rendered?.product_information?.counter_sunk)}
            </li>
            <li>
              Counter Sunk Quantity:{' '}
              {getValue(quotationItem?.item_rendered?.product_information?.counter_sunk_quantity)}
            </li>
            <li>
              Edge Plating:{' '}
              {getValue(quotationItem?.item_rendered?.product_information?.edge_plating)}
            </li>
            <li>
              Number of Edges:{' '}
              {getValue(quotationItem?.item_rendered?.product_information?.number_of_edges)}
            </li>
            <li>
              Carbon Print:{' '}
              {getValue(quotationItem?.item_rendered?.product_information?.carbon_print)}
            </li>
            <li>Slots: {getValue(quotationItem?.item_rendered?.product_information?.slots)}</li>
            <li>
              Hole Density:{' '}
              {getValue(quotationItem?.item_rendered?.product_information?.hole_density)}
            </li>
            <li>
              Gold Finger Quantity:{' '}
              {getValue(quotationItem?.item_rendered?.product_information?.gold_finger_quantity)}
            </li>
            <li>
              Half Path: {getValue(quotationItem?.item_rendered?.product_information?.half_path)}
            </li>
            <li>
              Peelable Mask:{' '}
              {getValue(quotationItem?.item_rendered?.product_information?.peelable_mask)}
            </li>
            <li>
              Micro Sec Report:{' '}
              {getValue(quotationItem?.item_rendered?.product_information?.micro_sec_report)}
            </li>
            <li>
              Destructive Report:{' '}
              {getValue(quotationItem?.item_rendered?.product_information?.destructive_report)}
            </li>
            <li>
              Solder Sample:{' '}
              {getValue(quotationItem?.item_rendered?.product_information?.solder_sample)}
            </li>
            <li>
              Low Matl Utilization:{' '}
              {getValue(quotationItem?.item_rendered?.product_information?.low_matl_utilization)}
            </li>
            <li>
              Utilization Percentage:{' '}
              {getValue(quotationItem?.item_rendered?.product_information?.utilization_perc)}
            </li>
            <li>
              Total Number of Drill Holes:{' '}
              {getValue(
                quotationItem?.item_rendered?.product_information?.total_number_drill_holes
              )}
            </li>
            <li>
              Coll Track Board:{' '}
              {getValue(quotationItem?.item_rendered?.product_information?.coll_track_board)}
            </li>
            <li>Stencil: {getValue(quotationItem?.item_rendered?.product_information?.stencil)}</li>
            <li>
              Stencil Type:{' '}
              {getValue(quotationItem?.item_rendered?.product_information?.stencil_type)}
            </li>
          </List>
        </Section>
      </div>

      <AdditionalCommentContainer>
        <ExtendedTitle>Additionnal comment</ExtendedTitle>
        <Section>
          {getValue(quotationItem?.item_rendered?.product_information?.additional_comment)}
        </Section>
        <ExtendedTitle>Attached files</ExtendedTitle>
        <SectionColumn>
          {quotationItem?.files?.map((file) => (
            <Button
              key={file.id}
              text={getFileName(file)}
              variant={ButtonVariant.PRIMARY_ELECTRIC}
              sizing={ButtonSizing.SMALL}
              onClick={() => getGerberFileDocument(file)}
            />
          ))}
        </SectionColumn>
      </AdditionalCommentContainer>
      <DocumentsItem quotationItem={quotationItem} onItemUpdate={onItemUpdate} />
    </ExtendedContainer>
  );
};

type ItemExtendedProps = {
  quotationItem: QuotationItem | OrderItem | ProviderPurchaseItem;
  onItemUpdate?: (
    updater: (
      prevItem: QuotationItem | OrderItem | ProviderPurchaseItem
    ) => QuotationItem | OrderItem | ProviderPurchaseItem
  ) => void;
};

type DocumentType = 'item-tooling' | 'item-non-compliance' | 'item-internal' | 'item-customer';

const DocumentsItem: React.FC<ItemExtendedProps> = ({ quotationItem, onItemUpdate }) => {
  const { post, del, get } = useApi();

  const onDrop = async (acceptedFiles: File[], documentType: DocumentType) => {
    const newDocuments = await Promise.all(
      acceptedFiles.map(async (file) => {
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('document_type', documentType);
        formData.append('instance_id', quotationItem.item_rendered.id.toString());

        const newDoc = await post(postDocument(), formData);
        return newDoc.data;
      })
    );

    onItemUpdate((prevItem) => {
      return {
        ...prevItem,
        item_rendered: {
          ...prevItem?.item_rendered,
          documents: {
            ...prevItem?.item_rendered?.documents,
            [documentType]: [
              ...(prevItem?.item_rendered?.documents[documentType] || []),
              ...newDocuments
            ]
          }
        }
      };
    });
  };

  const handleDelete = async (documentId: number, documentType: DocumentType) => {
    onItemUpdate((prevItem) => {
      const updatedDocuments = prevItem?.item_rendered?.documents[documentType]?.filter(
        (doc) => doc.document_id !== documentId
      );

      return {
        ...prevItem,
        item_rendered: {
          ...prevItem?.item_rendered,
          documents: { ...prevItem?.item_rendered?.documents, [documentType]: updatedDocuments }
        }
      };
    });

    await del(delDocument(documentId));
  };

  const handlePreview = async (documentId: number, documentName: string) => {
    const response = await get(getDocument(documentId), 'blob');

    const url = window.URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = url;
    link.download = documentName;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const Dropzone = ({ documentType }: { documentType: DocumentType }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { getRootProps, getInputProps } = useDropzone({
      onDrop: async (acceptedFiles) => {
        setIsLoading(true);
        await onDrop(acceptedFiles, documentType);
        setIsLoading(false);
      }
    });

    const getDocumentTypeDescription = (documentType: DocumentType): string => {
      switch (documentType) {
        case 'item-tooling':
          return 'Outillages';
        case 'item-non-compliance':
          return 'Non conformité';
        case 'item-internal':
          return 'Dossier PCB electronics';
        case 'item-customer':
          return 'Dossier Client';
      }
    };

    return (
      <div
        {...getRootProps()}
        style={{
          border: '2px dashed #1975FF',
          padding: '20px',
          textAlign: 'center'
        }}
      >
        {isLoading ? (
          <SmallLoader />
        ) : (
          <>
            <input {...getInputProps()} />
            <p
              style={{
                color: '#185FC9',
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '8px'
              }}
            >
              {getDocumentTypeDescription(documentType)}
              {Download()}
            </p>
          </>
        )}
      </div>
    );
  };

  const renderDocuments = (documents: Document[], documentType: DocumentType) => (
    <DocumentContainer>
      <Dropzone documentType={documentType} />
      <div>
        {documents?.map((doc) => (
          <DocumentListContainer key={doc.document_id}>
            <DocumentName>{doc.uploaded_date}</DocumentName>
            <DocumentName>{doc.file_name}</DocumentName>
            <ButtonGroup>
              <Button
                variant={ButtonVariant.PRIMARY_LIGHT}
                leftIcon={Download()}
                sizing={ButtonSizing.SMALL}
                onClick={() => handlePreview(doc.document_id, doc.file_name)}
              />
              <Button
                variant={ButtonVariant.ERROR}
                leftIcon={Trash()}
                sizing={ButtonSizing.ICON_OPTION}
                onClick={() => handleDelete(doc.document_id, documentType)}
              />
            </ButtonGroup>
          </DocumentListContainer>
        ))}
      </div>
    </DocumentContainer>
  );

  return (
    <DocumentsContainer>
      {renderDocuments(quotationItem.item_rendered.documents['item-customer'], 'item-customer')}
      {renderDocuments(quotationItem.item_rendered.documents['item-internal'], 'item-internal')}
      {renderDocuments(quotationItem.item_rendered.documents['item-tooling'], 'item-tooling')}
      {renderDocuments(
        quotationItem.item_rendered.documents['item-non-compliance'],
        'item-non-compliance'
      )}
    </DocumentsContainer>
  );
};

const ItemExtended: React.FC<ItemExtendedProps> = ({ quotationItem, onItemUpdate }) => {
  const [quotationItemState, setQuotationItemState] = useState(quotationItem);

  useEffect(() => {
    setQuotationItemState(quotationItem);
  }, [quotationItem]);

  const handleItemUpdate = (
    updater: (
      prevItem: QuotationItem | OrderItem | ProviderPurchaseItem
    ) => QuotationItem | OrderItem | ProviderPurchaseItem
  ) => {
    setQuotationItemState(updater);
    onItemUpdate?.(updater);
  };

  const productName = quotationItemState?.item_rendered?.product_name;

  switch (productName) {
    case 'pcb':
      return PCBExtended({ quotationItem: quotationItemState, onItemUpdate: handleItemUpdate });
    case 'study':
      return PCBStudy({ quotationItem: quotationItemState, onItemUpdate: handleItemUpdate });
    case 'cao':
      return PCBCao({ quotationItem: quotationItemState, onItemUpdate: handleItemUpdate });
    case 'stencil':
      return PCBStencil({ quotationItem: quotationItemState, onItemUpdate: handleItemUpdate });
    case 'component':
    case 'mechanic':
    case 'wiring':
      return PCBAdditionalComment({
        quotationItem: quotationItemState,
        onItemUpdate: handleItemUpdate
      });
    default:
      return PCBNoProduct();
  }
};

export default ItemExtended;

import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';

import { MainContainer, Title } from 'components/styledComponent';
import {
  Button,
  CustomDatePicker,
  CustomReactQuill,
  CustomSelect,
  CustomTextArea,
  SmallLoader
} from 'components/common';
import { getSelectStyles } from 'utils/style';
import {
  quotationStatusOption,
  paymentMethodOption,
  taxeRatesOption,
  termPaymentOption,
  addressSelectedTemplate,
  contactSelectedTemplate,
  useQuotationHandler,
  useQuotationVersionHandler,
  paymentModalityOption
} from 'utils/sales';
import { AdminsContext, AllCompaniesContactContext, AllCompaniesContext } from 'contexts';
import useApi from 'services/axios';
import {
  getClause,
  getSpecificQuotations,
  patchGeneralComment,
  patchNewQuotationVersion,
  postCreateCustomerOrder,
  postQuotationItemPDF
} from 'services/endpoints';
import { BlueCheckMark, Dashboard, Eye, WhiteCross } from 'svgs';
import QuotationRecapPrice from 'components/QuotationRecapPrice';
import SettingCard from 'components/SettingCard';
import { ButtonSizing, ButtonVariant } from 'components/common/Button';
import {
  NewQuotationVersionModal,
  QuotationVersionsModal,
  RelatedDocumentModal,
  RfqModal
} from 'components/modal';

import Items from './Items';
import GeneralDocuments from './GeneralDocuments';

const InputRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  gap: 8px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const CustomContainer = styled.div`
  gap: 4px;
  display: flex;
  flex-direction: column;
`;

const TitleSelect = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #434d56;
`;

const StyledTitle = styled(Title)`
  margin-bottom: 0;
`;

const ArraySectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 16px;
  gap 24px;
`;

const Quotation: React.FC = () => {
  const { state: adminsState } = useContext(AdminsContext.Context);
  const { state: allCompaniesContext } = useContext(AllCompaniesContext.Context);
  const { state: allCompaniesContactState } = useContext(AllCompaniesContactContext.Context);
  const [isLoading, setIsLoading] = useState(true);
  const [createdDate, setCreatedDate] = useState<string>();
  const [validatedDate, setValidatedDate] = useState<string>();
  const [quotationDate, setQuotationDate] = useState<string>();
  const [quotation, setQuotation] = useState<QuotationVersion | any>({});
  const [quotationDetail, setQuotationDetail] = useState<Partial<Quotation>>();
  const [quotationStatus, setQuotationStatus] = useState<Option>();
  const [companyOption, setCompanyOption] = useState<Option>();
  const [salesMan, setSalesMan] = useState<Option>();
  const [admin, setAdmin] = useState<Option>();
  const [clause, setClause] = useState<Option>();
  const [clauseOption, setClauseOption] = useState<Option>([]);
  const [paymentMethod, setPaymentMethod] = useState<Option>();
  const [paymentModality, setPaymentModality] = useState<Option>();
  const [termsPayment, setTermsPayment] = useState<NumberOption>();
  const [taxeRates, setTaxeRates] = useState<NumberOption>();
  const [shippingAddress, setShippingAddress] = useState<Option>();
  const [billingAddress, setBillingAddress] = useState<Option>();
  const [contact, setContact] = useState<Option>();
  const [currentContact, setCurrentContact] = useState<Option>();
  const [addressOption, setAddressOption] = useState<Company>();
  const [modalQuotationVersionDisplayed, setModalQuotationVersionDisplayed] =
    useState<boolean>(false);
  const [modalNewQuotationVersionDisplayed, setModalNewQuotationVersionDisplayed] =
    useState<boolean>(false);
  const [modalRelatedDocumentDisplayed, setModalRelatedDocument] = useState<boolean>(false);
  const [modalRfqDisplayed, setModalRfqDisplayed] = useState<boolean>(false);
  const [quotationVersion, setQuotationVersion] = useState<number>();
  const navigate = useNavigate();
  const location = useLocation();
  const [quotationId, setQuotationId] = useState<string>();
  const [quotationView, setQuotationView] = useState<string>();
  const [companyId, setCompanyId] = useState<string>();
  const [contactId, setContactId] = useState<string>();

  const { post, get, patch } = useApi();
  const onChangeQuotation = useQuotationHandler();
  const onChangeQuotationVersion = useQuotationVersionHandler();
  const isNewQuotation = quotationView === 'create';

  useEffect(() => {
    setQuotationId(new URLSearchParams(window.location.search).get('quotationId'));
    setQuotationView(new URLSearchParams(window.location.search).get('quotationView'));
    setCompanyId(new URLSearchParams(window.location.search).get('companyId'));
    setContactId(new URLSearchParams(window.location.search).get('contactId'));
  }, [location]);

  const lastQuotationVersion = useMemo(
    () =>
      quotationDetail?.quotation_versions?.reduce((max, current) =>
        current.id > max.id ? current : max
      ).id,
    [quotationDetail]
  );

  const isOldQuotationVersion = useMemo(
    () =>
      !isNewQuotation &&
      (lastQuotationVersion !== quotationVersion ||
        (quotationStatus?.value !== 'draft' &&
          quotationStatus?.value !== 'rfq' &&
          quotationStatus?.value !== 'ready')),
    [lastQuotationVersion, quotationVersion, isNewQuotation, quotationStatus]
  );

  const adminFormatted = useMemo(
    () =>
      adminsState.map((admin) => ({
        label: `${admin.last_name} ${admin.first_name}`,
        value: admin.id
      })),
    [adminsState]
  );

  const companyFormatted = useMemo(
    () =>
      allCompaniesContext.map((company) => ({
        label: company.company_name,
        value: company.id
      })),
    [allCompaniesContext]
  );

  const companiesContactFormatted = useMemo(
    () =>
      allCompaniesContactState
        .filter((contact) => contact.company_id === quotationDetail?.company_id)
        .map((contact) => ({
          label: contactSelectedTemplate(contact),
          value: contact.id
        })),
    [allCompaniesContactState, quotationDetail]
  );

  const shippingAddressOptionFormatted = useMemo(
    () =>
      addressOption?.addresses
        ? addressOption.addresses
            .filter((address) => address.address_type === 'shipping')
            .map((address) => ({
              label: addressSelectedTemplate(address),
              value: address.id
            }))
        : [],
    [addressOption]
  );

  const billingAddressOptionFormatted = useMemo(
    () =>
      addressOption?.addresses
        ? addressOption.addresses
            .filter((address) => address.address_type === 'billing')
            .map((address) => ({
              label: addressSelectedTemplate(address),
              value: address.id
            }))
        : [],
    [addressOption]
  );

  const handleCloseQuotationVersionModal = () => {
    setModalQuotationVersionDisplayed(false);
  };

  const handleCloseNewQuotationVersionModal = () => {
    setModalNewQuotationVersionDisplayed(false);
  };

  const handleCloseRelatedDocumentModal = () => {
    setModalRelatedDocument(false);
  };

  const handleCloseRfqModal = () => {
    setModalRfqDisplayed(false);
  };

  const handleChangeGeneralComment = () => {
    const updateGeneralComment = (newGeneralComment: string) => {
      patch(patchGeneralComment() + `?quotation_id=${quotationDetail.id}`, {
        content: newGeneralComment
      });
    };

    return updateGeneralComment;
  };

  const handleChangeAdditionalClauses = () =>
    onChangeQuotationVersion('additional_clauses', quotationDetail, quotation, setQuotation);

  const handleChangeAdditionalComment = () =>
    onChangeQuotationVersion('billing_information', quotationDetail, quotation, setQuotation);

  const handleChangeStatus = onChangeQuotationVersion(
    'status',
    quotationDetail,
    quotation,
    setQuotation,
    setQuotationStatus
  );

  const handleChangeCompany = onChangeQuotation(
    'company_id',
    quotationDetail,
    quotation,
    setQuotationDetail,
    setCompanyOption,
    currentContact
  );

  const handleChangeSaleMan = onChangeQuotation(
    'sales_person',
    quotationDetail,
    quotation,
    setQuotationDetail,
    setSalesMan,
    currentContact
  );

  const handleChangeAdmin = onChangeQuotation(
    'admin',
    quotationDetail,
    quotation,
    setQuotationDetail,
    setAdmin,
    currentContact
  );

  const handleChangeClause = onChangeQuotationVersion(
    'clause',
    quotationDetail,
    quotation,
    setQuotation,
    setClause
  );

  const handleChangeContact = onChangeQuotation(
    'contact_id',
    quotationDetail,
    quotation,
    setQuotationDetail,
    setContact,
    currentContact
  );

  useEffect(() => {
    if (currentContact !== quotationDetail?.contact_id) {
      setCurrentContact(contact);
    }
  }, [quotationDetail, contact]);

  const handleChangePaymentMethod = onChangeQuotationVersion(
    'payment_method',
    quotationDetail,
    quotation,
    setQuotation,
    setPaymentMethod
  );

  const handleChangePaymentModality = onChangeQuotationVersion(
    'payment_modality',
    quotationDetail,
    quotation,
    setQuotation,
    setPaymentMethod
  );

  const handleChangeTermsPayment = onChangeQuotationVersion(
    'terms_of_payment',
    quotationDetail,
    quotation,
    setQuotation,
    setTermsPayment
  );

  const handleChangeTaxeRates = onChangeQuotationVersion(
    'tax_rate',
    quotationDetail,
    quotation,
    setQuotation,
    setTaxeRates
  );

  const handleChangeShippingAddress = onChangeQuotationVersion(
    'delivery_address',
    quotationDetail,
    quotation,
    setQuotation,
    setShippingAddress
  );

  const handleChangeBillingAddress = onChangeQuotationVersion(
    'billing_address',
    quotationDetail,
    quotation,
    setQuotation,
    setBillingAddress
  );

  const handleChangeValidityDate = onChangeQuotationVersion(
    'validity_date',
    quotationDetail,
    quotation,
    setQuotation,
    setValidatedDate
  );

  const handleChangeQuotationDate = onChangeQuotationVersion(
    'quotation_date',
    quotationDetail,
    quotation,
    setQuotation,
    setQuotationDate
  );

  const handleQuotationVersionButtonClick = () => {
    setModalQuotationVersionDisplayed((status) => !status);
  };

  const handleNewQuotationVersionButtonClick = () => {
    setModalNewQuotationVersionDisplayed((status) => !status);
  };

  const newQuotationVersionButtonClick = async () => {
    const newQuotationVersion = await post(
      patchNewQuotationVersion(
        quotationDetail.company_id,
        quotationDetail.contact_id,
        quotationDetail.id,
        lastQuotationVersion
      ),
      {}
    );
    const newQuotationDetail = { ...quotationDetail };
    newQuotationDetail.quotation_versions.push(newQuotationVersion.data);
    setQuotationDetail(newQuotationDetail);
    setQuotationVersion(newQuotationVersion.data.id);
  };

  const updateQuotationStatus = (status: string) => {
    handleChangeStatus(status);
  };

  const handleDocumentButtonClick = () => {
    setModalRelatedDocument(true);
  };

  const handleRfqButtonClick = () => {
    setModalRfqDisplayed(true);
  };

  const handlePDFButtonClick = async () => {
    const quotationItemPDF = await post(postQuotationItemPDF(quotation.id), {}, 'blob');
    const blob = new Blob([quotationItemPDF.data], { type: 'application/pdf' });

    if (blob.size > 0) {
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `quotation-${quotationDetail?.reference}.pdf`;
      a.click();

      URL.revokeObjectURL(url);

      if (!quotation.is_locked) {
        updateQuotationStatus('sent');
      }
    }
  };

  const handleCreateCustomerOrderClick = async () => {
    await post(postCreateCustomerOrder, {
      quotation_id: quotationDetail.id,
      company: quotationDetail.company_id
    });
    navigate(`/Sales-Orders`);
  };

  const newAddressOption = allCompaniesContext.find(
    (company) => company.id === quotationDetail?.company_id
  );

  useEffect(() => {
    if (!isNewQuotation && quotationDetail) {
      const quotationToUpdate = quotationDetail;
      setQuotationVersion(
        quotationVersion
          ? quotationVersion
          : quotationDetail?.quotation_versions.reduce((max, current) =>
              current.id > max.id ? current : max
            ).id
      );
      setQuotationDetail(quotationToUpdate);
    }
  }, [isNewQuotation, quotationView, quotationId, quotationDetail]);

  useEffect(() => {
    if (quotationDetail && quotationVersion) {
      setQuotation(
        quotationDetail?.quotation_versions?.find((version) => version.id === quotationVersion)
      );
    }
  }, [quotationDetail, quotationVersion]);

  useEffect(() => {
    if (
      isNewQuotation &&
      quotationDetail?.company_id &&
      quotationDetail?.contact_id &&
      quotationDetail?.id
    ) {
      navigate(
        `/Sales-Quotations/Quotation?quotationId=${quotationDetail?.id}&companyId=${quotationDetail?.company_id}&contactId=${quotationDetail?.contact_id}&quotationView=updates`
      );
    }
  }, [quotationDetail]);

  useEffect(() => {
    setAddressOption(newAddressOption);
  }, [newAddressOption]);

  useEffect(() => {
    if (Object.keys(quotation).length) {
      if (quotationDetail?.company_id) {
        setCompanyOption(
          companyFormatted.find((company) => company.value === quotationDetail.company_id)
        );
      }
      if (quotationDetail?.contact_id) {
        setContact(
          companiesContactFormatted.find((contact) => contact.value === quotationDetail.contact_id)
        );
        setCurrentContact(
          companiesContactFormatted.find((contact) => contact.value === quotationDetail.contact_id)
        );
      }
      if (quotationDetail?.sales_person) {
        setSalesMan(adminFormatted.find((admin) => admin.value === quotationDetail.sales_person));
      }
      if (quotationDetail?.admin) {
        setAdmin(adminFormatted.find((admin) => admin.value === quotationDetail.admin));
      }
      if (quotation.billing_address) {
        setBillingAddress(
          billingAddressOptionFormatted.find(
            (address) => address.value === quotation.billing_address
          )
        );
      }
      if (quotation.delivery_address) {
        setShippingAddress(
          shippingAddressOptionFormatted.find(
            (address) => address.value === quotation.delivery_address
          )
        );
      }
      if (quotation.terms_of_payment) {
        setTermsPayment(
          termPaymentOption.find((option) => option.value === quotation.terms_of_payment)
        );
      }
      if (quotation.payment_method) {
        setPaymentMethod(
          paymentMethodOption.find((option) => option.value === quotation.payment_method)
        );
      }
      if (quotation.payment_modality) {
        setPaymentModality(
          paymentModalityOption.find((option) => option.value === quotation.payment_modality)
        );
      }
      if (quotation.tax_rate) {
        setTaxeRates(taxeRatesOption.find((option) => option.value === quotation.tax_rate));
      }

      if (quotation.status) {
        setQuotationStatus(
          quotationStatusOption.find((status) => status.value === quotation.status)
        );
      }

      if (quotation.created_at) {
        setCreatedDate(quotation.created_at);
      }

      if (quotation.validity_date) {
        setValidatedDate(quotation.validity_date);
      }

      if (quotation.clause) {
        setClause(clauseOption.find((option: Option) => option.value === quotation.clause));
      }
    }
  }, [quotation, shippingAddressOptionFormatted, clauseOption]);

  const refreshQuotation = async () => {
    if (quotationView === 'updates') {
      const quotationDetails = await get(
        getSpecificQuotations(Number(companyId), Number(contactId), Number(quotationId))
      );
      setQuotationDetail(quotationDetails.data);
    }
  };

  useEffect(() => {
    (async () => {
      const quotation = new URLSearchParams(window.location.search).get('quotationId');
      const company = new URLSearchParams(window.location.search).get('companyId');
      const contact = new URLSearchParams(window.location.search).get('contactId');
      const status = new URLSearchParams(window.location.search).get('quotationView');

      if (status === 'updates') {
        const quotationDetails = await get(
          getSpecificQuotations(Number(company), Number(contact), Number(quotation))
        );
        setQuotationDetail(quotationDetails.data);
      }
      const formattedClause: Option = (await get(getClause)).data.results.map((clause: Clause) => {
        return {
          label: clause.title,
          value: clause.title
        };
      });

      setClauseOption(formattedClause);
      setIsLoading(false);
    })();
  }, []);

  if (isLoading) {
    return <SmallLoader />;
  }

  return (
    <MainContainer>
      <Header>
        <InputRowContainer>
          <StyledTitle>
            {quotationDetail?.reference && quotation?.version_number
              ? `Quotation ${quotationDetail.reference}-v${quotation.version_number}`
              : 'Quotation'}
          </StyledTitle>
          <Button
            variant={ButtonVariant.PRIMARY_LIGHT}
            sizing={ButtonSizing.SMALL}
            text="version history"
            leftIcon={Eye()}
            onClick={handleQuotationVersionButtonClick}
          />
        </InputRowContainer>

        <InputRowContainer>
          <Button
            variant={ButtonVariant.SECONDARY}
            sizing={ButtonSizing.EXTRA_SMALL}
            text="New version"
            leftIcon={WhiteCross()}
            onClick={handleNewQuotationVersionButtonClick}
            disabled={quotationDetail?.is_order_created}
          />
          <CustomSelect
            placeholder="Draft"
            value={quotationStatus}
            options={quotationStatusOption}
            onChange={handleChangeStatus}
            isMulti={false}
            isSearchable={true}
            isDisabled={isOldQuotationVersion}
            styles={getSelectStyles(null, '200px', '50px')}
          />
        </InputRowContainer>
      </Header>
      <div style={{ display: 'flex', gap: '20px' }}>
        <div>
          <InputRowContainer>
            <CustomContainer>
              <TitleSelect>Company</TitleSelect>
              <CustomSelect
                placeholder="Company"
                value={companyOption}
                options={companyFormatted}
                onChange={handleChangeCompany}
                isDisabled={!isNewQuotation || companyOption || isOldQuotationVersion}
                styles={getSelectStyles(null, '200px', '50px', !companyOption)}
              />
            </CustomContainer>
            <CustomContainer>
              <TitleSelect>Salesman</TitleSelect>
              <CustomSelect
                placeholder="Salesman"
                value={salesMan}
                options={adminFormatted}
                onChange={handleChangeSaleMan}
                isMulti={false}
                isSearchable={true}
                isDisabled={isOldQuotationVersion}
                styles={getSelectStyles(null, '200px', '50px')}
              />
            </CustomContainer>
            <CustomContainer>
              <TitleSelect>Admin</TitleSelect>
              <CustomSelect
                placeholder="Admin"
                value={admin}
                options={adminFormatted}
                onChange={handleChangeAdmin}
                isMulti={false}
                isSearchable={true}
                isDisabled={isOldQuotationVersion}
                styles={getSelectStyles(null, '150px', '50px')}
              />
            </CustomContainer>
            <CustomContainer>
              <TitleSelect>Creation date</TitleSelect>
              <CustomDatePicker value={createdDate} isDisabled={true} />
            </CustomContainer>
            <CustomContainer>
              <TitleSelect>Validity date</TitleSelect>
              <CustomDatePicker
                value={validatedDate}
                isDisabled={isOldQuotationVersion}
                updatedDate={handleChangeValidityDate}
              />
            </CustomContainer>
            <CustomContainer>
              <TitleSelect>Quotation Date</TitleSelect>
              <CustomDatePicker
                value={quotationDate}
                isDisabled={isOldQuotationVersion}
                updatedDate={handleChangeQuotationDate}
              />
            </CustomContainer>
          </InputRowContainer>
          <InputRowContainer>
            <CustomContainer>
              <TitleSelect>Method of payment</TitleSelect>
              <CustomSelect
                placeholder="Method of payment"
                value={paymentMethod}
                options={paymentMethodOption}
                onChange={handleChangePaymentMethod}
                isMulti={false}
                isSearchable={true}
                isDisabled={isOldQuotationVersion}
                styles={getSelectStyles(null, '250px', '50px')}
              />
            </CustomContainer>
            <CustomContainer>
              <TitleSelect>Payment modality</TitleSelect>
              <CustomSelect
                placeholder="Net"
                value={paymentModality}
                options={paymentModalityOption}
                onChange={handleChangePaymentModality}
                isMulti={false}
                isSearchable={true}
                isDisabled={isOldQuotationVersion}
                styles={getSelectStyles(null, '150px', '50px')}
                usePortalStyles={true}
              />
            </CustomContainer>
            <CustomContainer>
              <TitleSelect>Terms of payment</TitleSelect>
              <CustomSelect
                placeholder="Terms of payment"
                value={termsPayment}
                options={termPaymentOption}
                onChange={handleChangeTermsPayment}
                isMulti={false}
                isSearchable={true}
                isDisabled={isOldQuotationVersion}
                styles={getSelectStyles(null, '250px', '50px')}
              />
            </CustomContainer>
            <CustomContainer>
              <TitleSelect>Taxe rate</TitleSelect>
              <CustomSelect
                placeholder="Taxe rate"
                value={taxeRates}
                options={taxeRatesOption}
                onChange={handleChangeTaxeRates}
                isDisabled={true}
                styles={getSelectStyles(null, '200px', '50px')}
              />
            </CustomContainer>
          </InputRowContainer>
          <InputRowContainer>
            <CustomContainer>
              <TitleSelect>Select billing address</TitleSelect>
              <CustomSelect
                placeholder="Billing address (by Name)"
                value={billingAddress}
                options={billingAddressOptionFormatted}
                onChange={handleChangeBillingAddress}
                isMulti={false}
                isSearchable={true}
                isDisabled={isOldQuotationVersion}
                styles={getSelectStyles(null, '250px', '110px')}
              />
            </CustomContainer>
            <CustomContainer>
              <TitleSelect>Select shipping address</TitleSelect>
              <CustomSelect
                placeholder="Shipping address (by Name)"
                value={shippingAddress}
                options={shippingAddressOptionFormatted}
                onChange={handleChangeShippingAddress}
                isMulti={false}
                isSearchable={true}
                isDisabled={isOldQuotationVersion}
                styles={getSelectStyles(null, '250px', '110px')}
              />
            </CustomContainer>
            <CustomContainer>
              <TitleSelect>Contact</TitleSelect>
              <CustomSelect
                placeholder="Contact"
                value={contact}
                options={companiesContactFormatted}
                onChange={handleChangeContact}
                isDisabled={isOldQuotationVersion}
                styles={getSelectStyles(null, '250px', '110px', !contact)}
              />
            </CustomContainer>
          </InputRowContainer>
          <InputRowContainer>
            <CustomTextArea
              title="Billing information"
              value={quotation.billing_information}
              placeholder="add comment"
              isDisabled={isOldQuotationVersion}
              callBack={handleChangeAdditionalComment}
              height="110px"
              debounceTime={1000}
            />
            <CustomContainer>
              <TitleSelect>Clause</TitleSelect>
              <CustomSelect
                placeholder="clause"
                value={clause}
                options={clauseOption}
                onChange={handleChangeClause}
                isMulti={false}
                isSearchable={true}
                isDisabled={isOldQuotationVersion}
                styles={getSelectStyles(null, '250px', '110px')}
              />
            </CustomContainer>
            <CustomTextArea
              title="Additional clauses"
              value={quotation.additional_clauses}
              isDisabled={isOldQuotationVersion}
              placeholder="Click to add clauses"
              callBack={handleChangeAdditionalClauses}
              height="110px"
              debounceTime={1000}
            />
          </InputRowContainer>
        </div>
        <GeneralDocuments
          quotation={quotationDetail}
          documentType="quotation-main-document"
          setQuotation={setQuotationDetail}
        />
      </div>
      <ArraySectionContainer>
        <SettingCard
          title="Quotation detail"
          icon={Dashboard()}
          buttons={
            <>
              <Button
                variant={ButtonVariant.SECONDARY}
                text="Create RFQ"
                leftIcon={WhiteCross()}
                sizing={ButtonSizing.EXTRA_SMALL}
                onClick={handleRfqButtonClick}
              />
              <Button
                variant={ButtonVariant.SECONDARY}
                text="Print client PDF"
                leftIcon={WhiteCross()}
                sizing={ButtonSizing.EXTRA_SMALL}
                onClick={handlePDFButtonClick}
              />
              <Button
                variant={ButtonVariant.PRIMARY_LIGHT}
                text="View documents"
                leftIcon={Eye()}
                onClick={handleDocumentButtonClick}
                sizing={ButtonSizing.EXTRA_SMALL}
              />
              <Button
                variant={ButtonVariant.PRIMARY}
                text="Turn to order"
                leftIcon={BlueCheckMark()}
                sizing={ButtonSizing.EXTRA_SMALL}
                onClick={handleCreateCustomerOrderClick}
                disabled={quotationDetail?.is_order_created}
              />
            </>
          }
        >
          {quotationDetail && (
            <Items
              quotationDetails={quotationDetail}
              quotationVersion={quotationVersion}
              currentContact={currentContact?.value ? currentContact?.value : currentContact}
              isNewQuotation={isNewQuotation}
              isOldQuotationVersion={isOldQuotationVersion}
            />
          )}
        </SettingCard>
        <QuotationRecapPrice
          quotationVersion={quotationVersion}
          quotationDetails={quotationDetail}
          isEditable={isOldQuotationVersion}
          refresh={refreshQuotation}
        />
        <CustomReactQuill
          title="General comment"
          text={quotation.general_comment}
          callBack={handleChangeGeneralComment}
          debounceTime={1000}
        />
      </ArraySectionContainer>
      {quotation && (
        <QuotationVersionsModal
          isDisplayed={modalQuotationVersionDisplayed}
          quotations={quotationDetail}
          onClose={handleCloseQuotationVersionModal}
          callback={setQuotationVersion}
        />
      )}
      <NewQuotationVersionModal
        isDisplayed={modalNewQuotationVersionDisplayed}
        onClose={handleCloseNewQuotationVersionModal}
        callback={newQuotationVersionButtonClick}
      />
      <RelatedDocumentModal
        title="Quotation related document"
        queryParam={`quotation_id=${quotationId}`}
        isDisplayed={modalRelatedDocumentDisplayed}
        onClose={handleCloseRelatedDocumentModal}
      />
      <RfqModal
        isDisplayed={modalRfqDisplayed}
        onClose={handleCloseRfqModal}
        quotation={quotationDetail}
      />
    </MainContainer>
  );
};

export default Quotation;

import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import useApi from 'services/axios';
import {
  patchCustomerDeliveryNote,
  patchGeneralComment,
  postCustomerDeliveryNotePDF
} from 'services/endpoints';
import { UpdateSalesDeliveryNoteRecap } from 'components/array/template';
import { Array } from 'components/array';
import { ButtonSizing, ButtonVariant } from 'components/common/Button';
import { Download, Eye } from 'svgs';
import { getSelectStyles } from 'utils/style';
import { AllCompaniesContactContext, AllCompaniesContext } from 'contexts';
import { useCompaniesState } from 'components/Tools';
import { addressSelectedTemplate, contactSelectedTemplate } from 'utils/sales';

import { Button, CustomInput, CustomReactQuill, CustomSelect, ModalSidebar } from '../common';
import { MainContainer, SubTitle } from '../styledComponent';

import RelatedDocumentModal from './RelatedDocumentModal';

const Content = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 8px;
`;

const Left = styled(Row)`
  justify-content: flex-end;
`;

const CustomContainer = styled.div`
  // gap: 4px;
  display: flex;
  flex-direction: column;
`;

const TitleSelect = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #434d56;
`;

const CustomTitle = styled.div`
  font-size: 14px;
  line-height: 1.5;
  color: rgb(67, 77, 86);
`;

const CustomText = styled.div`
  display: flex;
  height: 45.5px;
  width: 200px;
  padding-left: 16px;
  align-items: center;

  color: #1975ff;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
`;

type UpdatedDeliveryNoteModalProps = {
  isDisplayed: boolean;
  onClose: () => void;
  deliveryModalDetails: DeliveryNote;
  callback?: (newNote: DeliveryNote) => void;
};

const UpdatedDeliveryNoteModal: React.FC<UpdatedDeliveryNoteModalProps> = ({
  isDisplayed,
  onClose,
  deliveryModalDetails,
  callback
}) => {
  const [deliveryNote, setDeliveryNote] = useState<DeliveryNote>();
  const [modalRelatedDocumentDisplayed, setModalRelatedDocument] = useState<boolean>(false);
  const { findCompanyById } = useCompaniesState();
  const { state: allCompaniesContactState } = useContext(AllCompaniesContactContext.Context);
  const { state: allCompaniesContext } = useContext(AllCompaniesContext.Context);
  const { patch, post } = useApi();

  const getCompanyName = (companyId: number) => findCompanyById(companyId).company_name;

  const handleChange = (id: number) => (value: number) => {
    const new_customer_order_items: CustomerOrderItem[] = deliveryNote.customer_order_items.map(
      (item) => {
        if (item.id === id) {
          return {
            ...item,
            quantity: Number(value) > item.order_quantity ? item.order_quantity : Number(value)
          };
        } else {
          return item;
        }
      }
    );

    setDeliveryNote({
      ...deliveryNote,
      customer_order_items: new_customer_order_items
    });
  };

  const handleSaveButtonClick = async () => {
    const newDeliveryNote = await patch(patchCustomerDeliveryNote(deliveryNote.id), deliveryNote);
    setDeliveryNote(newDeliveryNote.data);
    callback(newDeliveryNote.data);
    onClose();
  };

  const handlePDFButtonClick = async () => {
    const PurchaseItemPDF = await post(postCustomerDeliveryNotePDF(deliveryNote.id), {}, 'blob');
    const blob = new Blob([PurchaseItemPDF.data], { type: 'application/pdf' });

    if (blob.size > 0) {
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `deliveryNote-${deliveryNote.reference}.pdf`;
      a.click();

      URL.revokeObjectURL(url);
    }
  };

  const handleCloseRelatedDocumentModal = () => {
    setModalRelatedDocument(false);
  };

  const handleDocumentButtonClick = () => {
    setModalRelatedDocument(true);
  };

  const handleChangeGeneralComment = () => {
    const updateGeneralComment = (newGeneralComment: string) => {
      patch(patchGeneralComment() + `?customer_delivery_note_id=${deliveryNote.id}`, {
        content: newGeneralComment
      });

      callback({
        ...deliveryNote,
        general_comment: newGeneralComment
      });
    };

    return updateGeneralComment;
  };

  const newAddressOption = allCompaniesContext.find(
    (company) => company.id === deliveryModalDetails?.company
  );

  const shippingAddressOptionFormatted = useMemo(
    () =>
      newAddressOption?.addresses
        ? newAddressOption.addresses
            .filter((address) => address.address_type === 'shipping')
            .map((address) => ({
              label: addressSelectedTemplate(address),
              value: address.id
            }))
        : [],
    [newAddressOption]
  );

  const companiesContactFormatted = useMemo(
    () =>
      allCompaniesContactState
        .filter((contact) => contact.id === deliveryModalDetails?.contact)
        .map((contact) => ({
          label: contactSelectedTemplate(contact),
          value: contact.id
        })),
    [allCompaniesContactState, deliveryModalDetails]
  );

  const handleInputChange = (name: string) => (value: string | number) => {
    setDeliveryNote({ ...deliveryModalDetails, [name]: value });
  };

  const salesDeliveryNotePreprocessing = deliveryModalDetails?.customer_order_items?.map((item) => {
    return {
      id: item.id,
      reference: { value: item.reference, display: <div>{item.reference}</div> },
      total_delivered_quantity: {
        value: item.order_quantity,
        display: <div>{item.order_quantity}</div>
      },
      total_remaining_quantity: {
        value: item.total_remaining_quantity,
        display: <div>{item.total_remaining_quantity}</div>
      },
      order_quantity: {
        value: item.quantity.toString(),
        display: (
          <CustomInput
            defaultValue={item.quantity.toString()}
            placeholder="reference"
            callBack={() => handleChange(item.id)}
            width="70px"
          />
        )
      }
    };
  });

  useEffect(() => {
    if (deliveryModalDetails) {
      setDeliveryNote(deliveryModalDetails);
    }
  }, [deliveryModalDetails]);

  return (
    <MainContainer>
      {isDisplayed && deliveryModalDetails && (
        <>
          <ModalSidebar isOpen={isDisplayed} onClose={onClose} title="Delivery note settings">
            <Content>
              <Row>
                <CustomContainer>
                  <CustomInput
                    title="Tracking Number"
                    defaultValue={deliveryModalDetails.tracking_number}
                    placeholder="#0000000"
                    callBack={() => handleInputChange('tracking_number')}
                  />
                </CustomContainer>
                <CustomContainer>
                  <CustomTitle>Related order</CustomTitle>
                  <CustomText>{deliveryModalDetails.order_reference}</CustomText>
                </CustomContainer>
                <CustomContainer>
                  <CustomTitle>Company</CustomTitle>
                  <CustomText>{getCompanyName(deliveryModalDetails.company)}</CustomText>
                </CustomContainer>
              </Row>

              <div>Select the products and quantities concerned by the delivery note</div>
              <Array
                template={UpdateSalesDeliveryNoteRecap}
                content={salesDeliveryNotePreprocessing}
                checkboxDisplayed={false}
                isSearchable={false}
              />
              <Left>
                <Button
                  variant={ButtonVariant.SECONDARY}
                  text={`Save`}
                  onClick={handleSaveButtonClick}
                />
              </Left>

              <SubTitle>Print & send delivery note</SubTitle>

              <Row>
                <CustomContainer>
                  <TitleSelect>Select shipping address</TitleSelect>
                  <CustomSelect
                    placeholder="Shipping address (by Name)"
                    value={shippingAddressOptionFormatted}
                    isDisabled={true}
                    styles={getSelectStyles(null, '250px', '85px')}
                  />
                </CustomContainer>
                <CustomContainer>
                  <TitleSelect>Contact</TitleSelect>
                  <CustomSelect
                    placeholder="Contact"
                    value={companiesContactFormatted}
                    isDisabled={true}
                    styles={getSelectStyles(null, '250px', '85px')}
                  />
                </CustomContainer>
              </Row>

              <Row>
                <Button
                  sizing={ButtonSizing.SMALL}
                  text={`Download delivery note`}
                  leftIcon={Download()}
                  onClick={handlePDFButtonClick}
                />
                <Button
                  text="View documents"
                  leftIcon={Eye()}
                  sizing={ButtonSizing.SMALL}
                  onClick={handleDocumentButtonClick}
                />
              </Row>
              <CustomReactQuill
                title="General comment"
                text={deliveryModalDetails.general_comment}
                callBack={handleChangeGeneralComment}
                debounceTime={1000}
              />
            </Content>
          </ModalSidebar>
          <RelatedDocumentModal
            title="Customer DeliveryNote related document"
            queryParam={`customer_delivery_note_id=${deliveryModalDetails?.id}`}
            isDisplayed={modalRelatedDocumentDisplayed}
            onClose={handleCloseRelatedDocumentModal}
          />
        </>
      )}
    </MainContainer>
  );
};

export default UpdatedDeliveryNoteModal;

import axios, { AxiosResponse } from 'axios';
import React, { useContext } from 'react';
import styled from 'styled-components';

import { ErrorContext, SuccessContext } from 'contexts';
import { getToken } from 'components/Tools';

const ErrorStatus = styled.p`
  font-weight: 600;
  font-size: 15px;
  line-height: 1.5;
  color: red;
  text-decoration: red underline;
  margin: 0;
`;

const ErrorRequest = styled.p`
  font-weight: 600;
  font-size: 15px;
  line-height: 1.5;
  color: red;
  text-decoration: red underline;
  margin: 0;
`;

const ErrorContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  align-items: center;
`;

const UrlContainer = styled(ErrorContainer)`
  gap: 4px;
`;

const displayErrors = (data: ErrorData): JSX.Element[] => {
  const elements: JSX.Element[] = [];

  Object.keys(data).forEach((key) => {
    const value = data[key];
    if (typeof value === 'string') {
      if (value.startsWith('doctype') || value.length > 100) {
        elements.push(
          <div key={key} style={{ whiteSpace: 'pre-wrap' }}>
            {value}
          </div>
        );
      } else {
        elements.push(<div key={key}>{value}</div>);
      }
    } else if (typeof value === 'number') {
      elements.push(<div key={key}>{value}</div>);
    } else if (Array.isArray(value)) {
      value.forEach((item: string, index) => {
        elements.push(
          <div key={`${key}-${index}`}>
            <b>{key}:</b> {item}
          </div>
        );
      });
    }
  });

  return elements;
};

const handleApiSuccess = (dispatch: React.Dispatch<SuccessDispatchAction>) => {
  dispatch({
    type: 'SHOW_SUCCESS',
    payload: <>Action successful</>
  });
};

const handleApiError = (
  error: any,
  dispatch: React.Dispatch<ErrorDispatchAction>,
  isShowError?: boolean
) => {
  const accessToken = getToken();

  if (error.response?.status === 401 && accessToken) {
    localStorage.removeItem('accessToken');
    location.reload();
    return;
  }

  if (isShowError) {
    dispatch({
      type: 'SHOW_ERROR',
      payload: (
        <ErrorContainer>
          <UrlContainer>
            <ErrorRequest>{error?.request?.responseURL}</ErrorRequest>
            <ErrorStatus>({error?.message})</ErrorStatus>
          </UrlContainer>

          {displayErrors(error.response.data as ErrorData)}
        </ErrorContainer>
      )
    });
  }
};

const getAuthToken = (): string | null => {
  const tokenString = localStorage.getItem('accessToken');
  const userToken = JSON.parse(tokenString);
  return userToken?.token;
};

const getHttp = (
  url: string,
  dispatch: React.Dispatch<ErrorDispatchAction>,
  responseType: 'json' | 'arraybuffer' | 'blob' = 'json',
  isShowError: boolean
): Promise<AxiosResponse> => {
  const token = getAuthToken();
  const headers = token ? { Authorization: `Bearer ${token}` } : {};
  return axios
    .get(url, { headers: headers, responseType })
    .then((result: AxiosResponse) => result)
    .catch((error) => {
      handleApiError(error, dispatch, isShowError);

      return error;
    });
};

const delHttp = (
  url: string,
  dispatch: React.Dispatch<ErrorDispatchAction>
): Promise<AxiosResponse> => {
  const token = getAuthToken();
  return axios
    .delete(url, { headers: { Authorization: `Bearer ${token}` } })
    .then((result: AxiosResponse) => result)
    .catch((error) => {
      handleApiError(error, dispatch);
      throw error;
    });
};

const postHttp = (
  url: string,
  obj: any,
  responseType: 'json' | 'arraybuffer' | 'blob' = 'json',
  errorDispatch: React.Dispatch<ErrorDispatchAction>,
  successDispatch: React.Dispatch<SuccessDispatchAction>
): Promise<AxiosResponse> => {
  const token = getAuthToken();
  const headers = token ? { Authorization: `Bearer ${token}` } : {};
  return axios
    .post(url, obj, {
      headers: headers,
      responseType
    })
    .then((result: AxiosResponse) => {
      handleApiSuccess(successDispatch);
      return result;
    })
    .catch((error) => {
      handleApiError(error, errorDispatch);
      throw error;
    });
};

const patchHttp = (
  url: string,
  obj: any,
  dispatch: React.Dispatch<ErrorDispatchAction>
): Promise<AxiosResponse> => {
  const token = getAuthToken();
  return axios
    .patch(url, obj, { headers: { Authorization: `Bearer ${token}` } })
    .then((result: AxiosResponse) => result)
    .catch((error) => {
      handleApiError(error, dispatch);
      throw error;
    });
};

const putHttp = (
  url: string,
  obj: any = {},
  dispatch: React.Dispatch<ErrorDispatchAction>
): Promise<AxiosResponse> => {
  const token = getAuthToken();
  return axios
    .put(url, obj, {
      headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' }
    })
    .then((result: AxiosResponse) => result)
    .catch((error) => {
      handleApiError(error, dispatch);
      throw error;
    });
};

const useApi = () => {
  const { dispatch: ErrorAction } = useContext(ErrorContext.Context);
  const { dispatch: SuccessAction } = useContext(SuccessContext.Context);

  return {
    get: (url: string, responseType: 'json' | 'arraybuffer' | 'blob' = 'json', isError?: boolean) =>
      getHttp(url, ErrorAction, responseType, isError),
    post: (url: string, obj: any, responseType: 'json' | 'arraybuffer' | 'blob' = 'json') =>
      postHttp(url, obj, responseType, ErrorAction, SuccessAction),
    patch: (url: string, obj: any) => patchHttp(url, obj, ErrorAction),
    put: (url: string, obj?: any) => putHttp(url, obj, ErrorAction),
    del: (url: string) => delHttp(url, ErrorAction)
  };
};

export default useApi;

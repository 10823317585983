import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const PurchaseOrderLink = styled.a`
  color: ${(props) => props.theme.colors.primary.main};
`;

type CustomLinkProps = {
  link: string;
  text: string;
};

const CustomLink: React.FC<CustomLinkProps> = ({ link, text }) => {
  const navigate = useNavigate();

  const handlePurchaseClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    navigate(link); //url
  };

  return (
    <PurchaseOrderLink
      href={link}
      target="_blank"
      onClick={(e) => handlePurchaseClick(e)}
      rel="noopener noreferrer"
    >
      {text}
    </PurchaseOrderLink>
  );
};

export default CustomLink;

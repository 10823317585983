import React, { useContext, useEffect, useState } from 'react';

import { Cross, Download, Trash } from 'svgs';
import { AdminsContext, CompaniesContactContext } from 'contexts';
import useApi from 'services/axios';
import { delContact, getSpecificContacts } from 'services/endpoints';
import { Button, SmallLoader } from 'components/common';
import { ButtonVariant } from 'components/common/Button';
import { BlueBoldTextContainer, HelperText } from 'utils/array';

import { Array, contactsCompany } from '../../array';
import { MainContainer } from '../../styledComponent';
import PictureTag from '../../PictrureTag';
import { capitalizeFirstLetter, getInitial } from '../../Tools';
import { ContactModal } from '../../modal';

type CompanyContactsProps = {
  companyDetail: Company;
};

const CompanyContacts: React.FC<CompanyContactsProps> = ({ companyDetail }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [contact, setContact] = useState<CompaniesContactContextState>();
  const [selectedContacts, setSelectedContacts] = useState<number[]>([]);
  const [contactDetails, setContactDetails] = useState<Contact>({
    company_id: companyDetail.id
  });
  const [modalCreateContactDisplayed, setModalCreateContactDisplayed] = useState<boolean>(false);
  const { state: companyContactState, dispatch: companyContactAction } = useContext(
    CompaniesContactContext.Context
  );
  const { state: adminsState } = useContext(AdminsContext.Context);
  const { del, get } = useApi();

  const getAdminInital = (adminId: number) => {
    const admin = adminsState.find((admin: Admin) => admin.id === adminId);

    if (admin) {
      return getInitial(admin.first_name, admin.last_name);
    }

    return `__`;
  };

  const unSelectedContacts = (id: number) => {
    setSelectedContacts((prevSelected) => prevSelected.filter((obj) => obj !== id));
  };

  const deleteContacts = () => {
    selectedContacts.map((companyId) => {
      del(delContact(companyDetail.id, companyId));
      unSelectedContacts(companyId);
    });

    const newCompaniesState = contact.results.filter(
      (company) => !selectedContacts.includes(company.id)
    );

    companyContactAction({
      type: 'UPDATE_DATA',
      payload: {
        ...companyContactState,
        count: companyContactState.count - 1,
        results: newCompaniesState
      }
    });

    setContact({
      ...companyContactState,
      count: companyContactState.count - 1,
      results: newCompaniesState
    });
  };

  const getPhoneNumberFormatted = (phoneNumber: string | null) => {
    if (phoneNumber === null) {
      return '';
    }
    return phoneNumber.replace(/.{2}/g, '$& ');
  };

  const companyContactPreProcessing = contact?.results
    .filter((contact) => contact.company_id === companyDetail.id)
    .map((contact) => ({
      id: contact.id,
      job_name: {
        value: contact.job_name,
        display: <div>{capitalizeFirstLetter(contact.job_name)}</div>
      },
      last_name: {
        value: contact.last_name,
        display: <div>{capitalizeFirstLetter(contact.last_name)}</div>
      },
      first_name: {
        value: contact.first_name,
        display: <div>{capitalizeFirstLetter(contact.first_name)}</div>
      },
      email_address: {
        value: contact.email_address,
        display: <BlueBoldTextContainer>{contact.email_address}</BlueBoldTextContainer>
      },
      address_name: {
        value: contact?.address?.address_name,
        display: contact?.address?.address_name
      },
      phone_number: {
        value: contact.phone_number,
        display: <div>{getPhoneNumberFormatted(contact.phone_number)}</div>
      },
      sales_person: {
        value: getAdminInital(contact.sales_person),
        display: <PictureTag text={getAdminInital(contact.sales_person)} imgUrl="" />
      }
    }));

  const unSelectedCompanies = (id: number) => {
    setSelectedContacts((prevSelected) => prevSelected.filter((obj) => obj !== id));
  };

  const handleLineSelected = (id: number, isUnSelected: boolean) => {
    if (isUnSelected) {
      unSelectedCompanies(id);
    } else {
      if (!selectedContacts.includes(id)) {
        setSelectedContacts((prevSelected) => [...prevSelected, id]);
      }
    }
  };

  const handleLineClicked = (event: React.MouseEvent<HTMLTableRowElement>) => {
    const id = event.currentTarget.id.split('-')[1];
    const contactDetails = contact.results.find((contact) => contact.id === Number(id));
    setContactDetails(contactDetails);
    setModalCreateContactDisplayed(true);
  };

  const openCreateContactModal = () => {
    setContactDetails({ company_id: companyDetail.id });
    setModalCreateContactDisplayed(true);
  };

  const handleCloseContactModal = () => {
    setModalCreateContactDisplayed(false);
  };

  const updateCustomerContact = (newPage: any) => {
    setContact(newPage);
  };

  useEffect(() => {
    (async () => {
      const contact = await get(getSpecificContacts(companyDetail.id));
      setContact(contact.data);
      setIsLoading(false);
    })();
  }, []);

  if (isLoading) {
    return <SmallLoader />;
  }

  return (
    <MainContainer>
      <Array
        template={contactsCompany}
        content={companyContactPreProcessing}
        lineSelected={handleLineSelected}
        lineClicked={handleLineClicked}
        pagination={contact}
        updatedArrayData={updateCustomerContact}
        isSearchable={false}
        tools={
          <>
            <Button
              variant={ButtonVariant.PRIMARY}
              text="Contact"
              leftIcon={Cross()}
              onClick={openCreateContactModal}
            />
            <Button variant={ButtonVariant.PRIMARY} text="Export" leftIcon={Download()} />
            {!!selectedContacts.length && (
              <Button
                variant={ButtonVariant.ERROR}
                text={`Delete (${selectedContacts.length}) rows`}
                leftIcon={Trash()}
                onClick={deleteContacts}
              />
            )}
          </>
        }
      />
      {!companyContactPreProcessing.length && (
        <HelperText>Add a contacts start a business with this company</HelperText>
      )}
      <ContactModal
        isDisplayed={modalCreateContactDisplayed}
        contactDetail={contactDetails}
        companyDetail={companyDetail}
        onClose={handleCloseContactModal}
        setContacts={setContact}
        contacts={contact}
      />
    </MainContainer>
  );
};

export default CompanyContacts;
